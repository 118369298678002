import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Thumb from "@material-ui/icons/ThumbUp";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import { Typography, Button, Avatar, Grid, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Check from "@material-ui/icons/Check";
import moment from "moment";
import log from "loglevel";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";

const THE_BLUE = "#0889F8";

const style = (theme) => ({
  root: {},
  blue: {
    color: THE_BLUE,
  },
  paper: {
    width: "90%",
    margin: theme.spacing(3),
    padding: theme.spacing(4),
    backgroundColor: "transparent",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      width: "100%",
      padding: theme.spacing(2),
    },
  },
  selected: {
    backgroundColor: "#B9FFCA",
  },
  title: {
    color: THE_BLUE,
    marginBottom: theme.spacing(3),
  },
  tab: {
    marginBottom: theme.spacing(3),
  },
  jobType: {
    color: THE_BLUE,
    marginBottom: theme.spacing(2),
  },
  box1: {
    marginBottom: theme.spacing(2),
  },
  box2: {
    marginRight: theme.spacing(2),
  },
  application: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(0.5),
    fontSize: 32,
    width: 60,
    height: 60,
  },
  profile: {},
  thumbBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  thumb: {
    fontSize: 70,
    color: THE_BLUE,
  },
  check: {
    fontSize: 70,
    color: "#1CCF02",
  },
  boxLeft: {
    height: "100%",
  },
  desc: {
    marginBottom: theme.spacing(5),
  },
  priorityBox: {
    marginBottom: theme.spacing(5),
  },
  priority: {
    marginRight: theme.spacing(1),
  },
  attachFile: {
    fontSize: 20,
  },
  thumbsUpDown: {
    marginLeft: theme.spacing(1),
  },
  tips1: {
    marginBottom: theme.spacing(1),
  },
  aben: {
    justifyContent: "space-between",
  },
  checkmark: {
    fill: "gray",
  },
  name: {
    display: "flex",
  },
});

function ReviewApplications({
  classes,
  jobApplications,
  handlePreQualification,
  handleViewedApplication,
  handleDownload,
  isAdmin = false,
  bidStartedAt,
}) {
  const history = useHistory();

  console.log("jobApplications", jobApplications);
  // filter view application
  const applicantIds = jobApplications
    .filter((e) => !e.viewedApplication)
    .map((e) => e._id);
  if (applicantIds.length) {
    handleViewedApplication(applicantIds);
  }

  function handleProfile(id) {
    history.push(`/profile/${id}`);
  }

  function handleDownloadSelf(jobApplication) {
    log.info("to download file:", jobApplication);
    window.open(
      `${process.env.REACT_APP_S3_URL_PREFIX}/${jobApplication.offerFileName}`,
      "_blank",
    );
    handleDownload(jobApplication._id);
  }

  return (
    <>
      <Grid container justify="flex-end" className={classes.tips1}>
        <Grid item>
          <Typography variant="subtitle1" className={classes.blue}>
            Godkend til prækvalifikation
          </Typography>
        </Grid>
        <Grid item>
          <ThumbsUpDownIcon
            className={clsx(classes.blue, classes.thumbsUpDown)}
          />
        </Grid>
      </Grid>
      <Grid role="list">
        {jobApplications.map((jobApplication) => (
          <Paper
            role="listitem"
            elevation={2}
            key={jobApplication._id}
            className={clsx(
              jobApplication.acceptedForPrequalification
                ? classes.selected
                : {},
            )}
          >
            <Grid
              justify="space-between"
              className={classes.application}
              container
              direction="row"
            >
              <Grid item xs={12} sm={2}>
                <Grid
                  container
                  direction="column"
                  className={classes.boxLeft}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Box className={classes.name}>
                      <Typography
                        className={clsx(classes.userName, classes.blue)}
                        variant="h6"
                      >
                        {jobApplication.applicantId.firstName}
                      </Typography>
                      {jobApplication.applicantId?.idVerified && (
                        <Tooltip title={"Denne bruger er verificeret"}>
                          <Check className={classes.checkmark} />
                        </Tooltip>
                      )}
                    </Box>
                    {isAdmin && (
                      <Typography variant="subtitle1">
                        {jobApplication.applicantId.email}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Avatar
                      className={classes.avatar}
                      alt="logo"
                      src={_.get(
                        jobApplication,
                        "applicantId.profile.photo.urlThumbnail",
                        "http://",
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() =>
                        handleProfile(jobApplication.applicantId._id)
                      }
                      variant="contained"
                      color="primary"
                      disabled={false}
                    >
                      PROFIL
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container className={classes.aben}>
                      <Grid item>
                        <Typography
                          className={classes.blue}
                          variant="subtitle2"
                        >
                          Skriv kort til ordregiver om, hvorfor du gerne vil
                          give tilbud på udbud
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          {moment(jobApplication.createdTime).format(
                            "yyyy-MM-DD HH:mm",
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography>{jobApplication.message}</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="subtitle2"
                          className={classes.blue}
                        >
                          Skriv kort til ordregiver om, hvordan du vil løse
                          udbuddet
                        </Typography>
                        <Typography>{jobApplication.resolve}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="subtitle2"
                          className={classes.blue}
                        >
                          Ordregiver har skrevet nogle kriterier som er vigtige
                          for udbuddet - Tilknyt kommentar til hvordan de
                          kriterier vil løses
                        </Typography>
                        <Typography>{jobApplication.priority}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} className={classes.thumbBox}>
                {!jobApplication.acceptedForPrequalification && (
                  <Button
                    onClick={() => handlePreQualification(jobApplication)}
                    title="prækvalifikation ansøgeren"
                    disabled={isAdmin || bidStartedAt}
                  >
                    <Thumb
                      className={classes.thumb}
                      style={isAdmin ? { color: "gray" } : {}}
                    />
                  </Button>
                )}
                {jobApplication.acceptedForPrequalification &&
                  !jobApplication.offerFileName && (
                    <Grid
                      container
                      direction="column"
                      justfiy="center"
                      alignItems="center"
                    >
                      <Button
                        onClick={() => handlePreQualification(jobApplication)}
                        title="prækvalifikation ansøgeren"
                        disabled={isAdmin || bidStartedAt}
                      >
                        <Thumb
                          className={classes.thumb}
                          style={{
                            color: jobApplication.acceptedForPrequalification
                              ? "green"
                              : "blue",
                          }}
                        />
                      </Button>
                    </Grid>
                  )}
                {/* PQ, offered, but not download */}
                {jobApplication.acceptedForPrequalification &&
                  jobApplication.offerFileName &&
                  !jobApplication.offerFileDownloadAt && (
                    <Grid
                      container
                      direction="column"
                      justfiy="center"
                      alignItems="center"
                    >
                      <Badge color="secondary" variant="dot">
                        <Button
                          onClick={() => handleDownloadSelf(jobApplication)}
                          variant="contained"
                          color="primary"
                        >
                          Hent tilbud
                        </Button>
                      </Badge>
                    </Grid>
                  )}
                {jobApplication.acceptedForPrequalification &&
                  jobApplication.offerFileName &&
                  jobApplication.offerFileDownloadAt && (
                    <Grid
                      container
                      direction="column"
                      justfiy="center"
                      alignItems="center"
                    >
                      <Button
                        onClick={() => handleDownloadSelf(jobApplication)}
                        variant="contained"
                        color="primary"
                      >
                        Hent tilbud
                      </Button>
                    </Grid>
                  )}
              </Grid>
              {isAdmin && (
                <Grid item xs={12} sm={2} className={classes.thumbBox}>
                  {jobApplication.disabledComponent}
                </Grid>
              )}
            </Grid>
          </Paper>
        ))}
      </Grid>
    </>
  );
}

ReviewApplications.propTypes = {
  jobApplications: PropTypes.array.isRequired,
};

export default withStyles(style)(ReviewApplications);
