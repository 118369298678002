import React from "react";
import Modal from "@material-ui/core/Modal";
import axios from "../axios";
import { useAuth } from "context/auth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import log from "loglevel";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ServicesSelector from "./common/ServicesSelector";
import * as utils from "../utils";

const styles = (theme) => ({
  root: {
    maxHeight: "90%",
    overflow: "auto",
    marginTop: 20,
    position: "absolute",
    margin: 10,
    padding: 19,
    maxWidth: 800,
    minWidth: 329,
    left: "50%",
    transform: "translate(-50%, 0px)",
    "& .MuiTableCell-root": {
      padding: 6,
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  creditBox: {
    width: "100%",
  },
  payButtonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  payButton: {
    minWidth: 300,
  },
  applicantListBox: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  buttonBox: {
    padding: "4px",
    justifyContent: "space-around",
  },
});

function ConfirmWinnerModal(props) {
  const { classes } = props;
  const { authToken } = useAuth();
  const history = useHistory();
  const [position, setPosition] = React.useState([]);
  const [user, setUser] = React.useState(undefined);

  async function load() {
    axios
      .get("/api/users", {
        headers: {
          Authorization: authToken.token,
        },
      })
      .then((res) => {
        log.warn("xxx:", res.data);
        setUser(res.data);
      })
      .catch((err) => {
        utils.handleAxiosError(err);
      });
  }

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePositionChange(e) {
    log.warn("handle select changee.target:", e.target);
    if (e.target.value.some((v) => v === -1)) {
      log.warn("all");
      setPosition([]);
      return;
    }
    setPosition(e.target.value);
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
  };

  async function handleYes() {
    await axios
      .request({
        url: `/api/users/${user._id}/services`,
        method: "PUT",
        headers: {
          Authorization: authToken.token,
        },
        data: {
          services: position,
        },
      })
      .then((res) => {
        message.success("lykkedes!", 5);
        setTimeout(() => {
          history.push("/jobs");
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        utils.handleAxiosError(err);
      });
  }

  async function handleNo() {
    await axios
      .request({
        url: `/api/users/${user._id}/services/ignore`,
        method: "PUT",
        headers: {
          Authorization: authToken.token,
        },
      })
      .then((res) => {
        // message.success("lykkedes!", 5);
        setTimeout(() => {
          history.push("/jobs");
        }, 2000);
      })
      .catch((err) => {
        utils.handleAxiosError(err);
      });
  }

  return (
    <>
      <Modal open={true}>
        <form onSubmit={handleSubmit}>
          <Paper className={classes.root}>
            <Grid container>
              <Grid item>
                <Typography variant="h5" color="primary">
                  Er der nogle af disse faggrupper du og din virksomhed
                  tilbyder?
                </Typography>
                <ServicesSelector
                  handlePositionChange={handlePositionChange}
                  position={position}
                />
                <Grid container className={classes.buttonBox}>
                  <Button
                    onClick={handleYes}
                    variant="contained"
                    color="primary"
                  >
                    Indsend
                  </Button>
                  <Button
                    onClick={handleNo}
                    variant="contained"
                    color="secondary"
                  >
                    Nej vi er kun ordregivere
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Modal>
    </>
  );
}

export default withStyles(styles)(ConfirmWinnerModal);
