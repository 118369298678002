import React, { useEffect, useState } from "react";
import axios from "../axios";
import { useAuth } from "context/auth";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import JobCard from "components/JobCard";
import { useHistory } from "react-router-dom";

const MyApplications = (props) => {
  const [myApplications, setMyApplications] = useState([]);
  const { authToken } = useAuth();
  const history = useHistory();

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    try {
      const response = await axios.get("/api/applications", {
        headers: {
          Authorization: authToken.token,
        },
      });
      console.log(response.data);
      setMyApplications(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const acceptedQualifications = myApplications.filter(
    (app) => app.acceptedForPrequalification,
  );
  const notAcceptedQualifications = myApplications.filter(
    (app) => !app.acceptedForPrequalification,
  );

  return (
    <Grid container style={{ marginTop: 100 }}>
      <Typography
        className="blue"
        color="primary"
        variant="h4"
        style={{
          margin: "0 10px",
        }}
      >
        Godkendt
      </Typography>
      <Grid container>
        {acceptedQualifications &&
          acceptedQualifications.length > 0 &&
          acceptedQualifications.map((app) => {
            const jobId = app.jobId;
            return (
              <JobCard
                onClick={() => history.push(`/myapplications/${app._id}`)}
                key={app._id}
                {...jobId}
                enableRate={false}
              />
            );
          })}
      </Grid>
      <Typography
        className="blue"
        color="primary"
        variant="h4"
        style={{
          margin: "0 10px",
        }}
      >
        Afventer
      </Typography>
      <Grid container>
        {notAcceptedQualifications &&
          notAcceptedQualifications.length > 0 &&
          notAcceptedQualifications.map((app) => {
            const jobId = app.jobId;
            return (
              <JobCard
                onClick={() => history.push(`/myapplications/${app._id}`)}
                key={app._id}
                {...jobId}
              />
            );
          })}
      </Grid>
    </Grid>
  );
};
export default MyApplications;
