import React from "react";
import log from "loglevel";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

const styles = (_theme) => ({
  root: {
    flexDirection: "column",
    alignItems: "center",
  },
});

function AvatarSmall(props) {
  const { user, classes } = props;
  log.debug("avatar small:", user);
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Avatar
          alt="logo"
          className={classes.avatar}
          src={_.get(user, "profile.photo.urlThumbnail", "---")}
        />
      </Grid>
      <Grid item>
        <Typography>{_.get(user, "firstName", "---")}</Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(AvatarSmall);
