import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Modal from "./common/CBModal2";
import SubscribeDialog from "./SubscribeDialog";

const styles = (theme) => ({
  root: {
    maxHeight: "90%",
    overflow: "auto",
    position: "absolute",
    marginTop: 30,
    margin: 10,
    padding: 20,
    maxWidth: 800,
    minWidth: 329,
    left: "50%",
    transform: "translate(-50%, 0px)",
    "& .MuiTableCell-root": {
      padding: 6,
    },
  },
  creditBox: {
    width: "100%",
  },
  payButtonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  payButton: {
    minWidth: 300,
  },
  applicantListBox: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  buttonBox: {
    justifyContent: "space-around",
    marginTOp: theme.spacing(5),
  },
});

function CooldownModal(props) {
  const { classes } = props;
  const history = useHistory();
  const [isPaymentOpen, setIsPaymentOpen] = React.useState(false);

  async function handleYes() {
    setIsPaymentOpen(true);
  }

  async function handleNo() {
    history.push("/jobs");
  }

  function handleSuccess() {
    message.success("Succes med abonnement", 5);
    setTimeout(() => {
      console.log("The history:");
      console.log(history);
      // if (history.length > 1) {
      //   history.goBack();
      // } else {
      history.push("/jobs");
      window.location.reload();
      // }
    }, 2000);
  }

  return (
    <>
      <Modal open={true}>
        <Paper className={classes.root}>
          <Grid container>
            <Grid item>
              <Typography variant="h5" color="primary">
                Bygnetværk Abonnement
              </Typography>
              {!isPaymentOpen && (
                <>
                  <Typography variant="body1">
                    Din gratis periode er ovre. Vil du forsætte med et
                    abonnement på bygnetvaerk.dk til 250DKK inkl. moms.?
                  </Typography>
                  <Box height={10} />
                  <Grid container className={classes.buttonBox}>
                    <Button
                      onClick={handleYes}
                      variant="contained"
                      color="primary"
                    >
                      Ja tak
                    </Button>
                    <Button
                      onClick={handleNo}
                      variant="contained"
                      color="secondary"
                    >
                      Måske senere
                    </Button>
                  </Grid>
                </>
              )}
              {isPaymentOpen && <SubscribeDialog onSuccess={handleSuccess} />}
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}

export default withStyles(styles)(CooldownModal);
