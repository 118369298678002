import log from "loglevel";
/*
 * The select list of the job types
 */
export const JOB_POSITIONS = [
  // TODO: need fetch from Backend
  // Use only ID numbers when posting from client,
  //to avoid anyone posting something like "donkey, balls" to express
  { id: 0, name: "Anlægsarbejde generelt" },
  { id: 1, name: "Arbejde generelt" },
  { id: 2, name: "Byggeplads, Transport" },
  { id: 3, name: "Byggeri generelt" },
  { id: 4, name: "Elektriker, VVS, Blikkenslager" },
  { id: 5, name: "Entreprenør generelt" },
  { id: 6, name: "Gartner, Kloak, Jord" },
  { id: 7, name: "Håndværker generelt" },
  { id: 8, name: "Installationer" },
  { id: 9, name: "Køkken, Inventar" },
  { id: 10, name: "Maler, Gulv, Loft" },
  { id: 11, name: "Materialer" },
  { id: 12, name: "Materiel" },
  { id: 13, name: "Murer, Struktør" },
  { id: 14, name: "Nedriver, Rengøring" },
  { id: 15, name: "Nedrivning" },
  { id: 16, name: "Nybyggeri" },
  { id: 17, name: "Om- og tilbygning" },
  { id: 18, name: "Renovering" },
  { id: 19, name: "Rådgiver generelt" },
  { id: 20, name: "Smed, Glarmester" },
  { id: 21, name: "Specialist" },
  { id: 22, name: "Tømrer, Snedker, Tagdækker" },
  { id: 23, name: "Antennefirma" },
  { id: 24, name: "Elevator" },
  { id: 25, name: "Fuge" },
  { id: 26, name: "Anlægsgartner" },
  { id: 27, name: "Glarmester" },
  { id: 28, name: "Isolatør" },
  { id: 29, name: "Kølemontør, Låsesmed" },
  { id: 30, name: "Arkitekt, ingeniør, landmåler" },
];

export const PRIORITIES = [
  // TODO: need fetch from Backend
  {
    id: 0,
    name: "lav pris",
  },
  {
    id: 1,
    name: "bæredygtighed",
  },
  {
    id: 2,
    name: "samarbejde",
  },
  {
    id: 3,
    name: "ui",
  },
  {
    id: 4,
    name: "design",
  },
  {
    id: 5,
    name: "app",
  },
  {
    id: 6,
    name: "iphone",
  },
  {
    id: 7,
    name: "interface",
  },
];

export const BUDGETS = [
  {
    id: 0,
    text: "0 - 50.000kr",
    text2: "0-50 t.kr.",
    prices: [600, 300, 200, 150],
  },
  {
    id: 1,
    text: "50.000kr - 500.000kr",
    text2: "50-500 t.kr.",
    prices: [780, 390, 260, 195],
  },
  {
    id: 2,
    text: "500.000kr - 3.000.000kr",
    text2: "500 t.kr-3 mio.kr.",
    prices: [960, 480, 320, 240],
  },
  {
    id: 3,
    text: "Over 3 mio.kr",
    text2: "+3 mio.kr.",
    prices: [960, 480, 320, 240],
  },
];

export const TASK_CRITERIES = [
  {
    group_name: "Pris",
    items: [
      {
        id: 0,
        name: "Billigst muligt",
      },
      {
        id: 1,
        name: "Fair pris",
      },
      {
        id: 2,
        name: "Betaling ved aflevering",
      },
      {
        id: 3,
        name: "Budgetsikkerhed",
      },
      {
        id: 4,
        name: "Fast pris",
      },
    ],
  },
  {
    group_name: "Kvalitet",
    items: [
      {
        id: 5,
        name: "Fair kvalitet",
      },
      {
        id: 6,
        name: "Høj kvalitet",
      },
      {
        id: 7,
        name: "Kvalitet afstemt prisen",
      },
      {
        id: 8,
        name: "Garanti",
      },
      {
        id: 9,
        name: "Kvalitetssikring",
      },
    ],
  },
  {
    group_name: "Tid",
    items: [
      {
        id: 10,
        name: "Snarest muligt",
      },
      {
        id: 11,
        name: "Byggetid",
      },
      {
        id: 12,
        name: "Overholdelse af aftalt tidsplan",
      },
      {
        id: 13,
        name: "Tidsplan udarbejdes og afstemmes",
      },
      {
        id: 14,
        name: "Fleksibilitet",
      },
    ],
  },
  {
    group_name: "Erfaring",
    items: [
      {
        id: 15,
        name: "God erfaring",
      },
      {
        id: 16,
        name: "Bemanding",
      },
      {
        id: 17,
        name: "Organisation",
      },
      {
        id: 18,
        name: "Fagfolk",
      },
      {
        id: 19,
        name: "Garanti og servide",
      },
      {
        id: 20,
        name: "Service",
      },
    ],
  },
  {
    group_name: "Øvrige",
    items: [
      {
        id: 21,
        name: "Bæredygtigt byggeri",
      },
      {
        id: 22,
        name: "Sociale klausuler",
      },
    ],
  },
];

TASK_CRITERIES.findNameByValue = (value) => {
  log.warn("to find name by value:", value);
  const name = TASK_CRITERIES.reduce((a, c) => {
    const found = c.items.find((item) => {
      return item.id === value;
    });
    if (found) {
      return found.name;
    } else {
      return a;
    }
  }, null);
  log.warn("found:", name);
  return name;
};
