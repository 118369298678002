const { message: messageBox } = require("antd");
const log = require("loglevel");

module.exports = {
  /*
   * Return:
   * {
   *  message: [string],
   *  status: [number],
   * }
   */
  parseAxiosError(error) {
    log.warn("raw e from axios:", error);
    const result = {
      message: "Sorry, noget gik galt",
      status: 500,
    };
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        result.message = error.response.data.message;
      }
      if (
        error.response &&
        error.response.data &&
        error.response.data.location
      ) {
        result.location = error.response.data.location;
      }
      if (error.response.status) {
        result.status = error.response.status;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      //console.log(error.request);
      result.message = "can not get response from server";
    } else {
      // Something happened in setting up the request that triggered an Error
      log.log("Error", error.message);
      result.message = error.message;
    }
    return result;
  },
  handleAxiosError(error) {
    const result = this.parseAxiosError(error);
    const { message, status, location } = result;
    if (status === 302) {
      if (!location) {
        throw new Error("302 but no location");
      }
      window.location.href = location;
      return;
    }
    const n = Math.round(status / 100);
    if (n === 2) {
      return;
    }
    if (n === 5) {
      messageBox.error(message);
    } else {
      messageBox.warn(result.message);
    }
    throw new Error(result.message);
  },
};
