/*
 * A temporarily component for test payment
 */
import React from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import log from "loglevel";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { message } from "antd";
// import { makeStyles } from "@material-ui/core/styles";
import Payment from "./Payment";

// const useStyles = makeStyles((theme) => ({
//   paymentContainer: {},
// }));

// function CardSection() {
//   const [message, setMessage] = React.useState("");
//   const [expireMessage, setExpireMessage] = React.useState("");
//   const [cvcMessage, setCvcMessage] = React.useState("");
//   const [brand, setBrand] = React.useState("");
//   const classes = useStyles();

//   function handleChange(e) {
//     log.warn("e:", e);
//     if (e.error) {
//       setMessage(e.error.message);
//     } else {
//       setMessage("");
//     }
//     if (e.brand) {
//       setBrand(e.brand);
//     } else {
//       setBrand("");
//     }
//   }

//   function handleChangeExpire(e) {
//     log.warn("e:", e);
//     if (e.error) {
//       setExpireMessage(e.error.message);
//     } else {
//       setExpireMessage("");
//     }
//   }

//   function handleChangeCvc(e) {
//     log.warn("e:", e);
//     if (e.error) {
//       setCvcMessage(e.error.message);
//     } else {
//       setCvcMessage("");
//     }
//   }

//   return (
//     <Box className={classes.paymentContainer}>
//       <Box>{brand}</Box>
//       <CardNumberElement onChange={handleChange} />
//       <Grid container>
//         <Grid item xs={5}>
//           <CardExpiryElement onChange={handleChangeExpire} />
//           <Box>{expireMessage}</Box>
//         </Grid>
//         <Grid item xs={5}>
//           <CardCvcElement onChange={handleChangeCvc} />
//           <Box>{cvcMessage}</Box>
//         </Grid>
//       </Grid>
//       <Box>{message}</Box>
//     </Box>
//   );
// }

function PaymentSandbox() {
  const [secret, setSecret] = React.useState(undefined);

  async function load() {
    //get all applications for current user
    await axios
      .request({
        url: "/test/payment/init",
        method: "GET",
      })
      .then((res) => {
        log.info("res:", res.data);
        setSecret(res.data.paymentSecret);
      });
  }

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: "Dadior Chen",
          email: "dadiorchen@outlook.com",
          phone: "12345678",
        },
      },
      setup_future_usage: "off_session",
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        log.info("payment success!");
        axios
          .request({
            url: "/test/payment/confirm",
            method: "post",
            data: {
              secret,
            },
          })
          .then((res) => {
            log.info("res:", res);
          });
      } else {
        log.warn("payment result:", result.paymentIntent);
      }
    }
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "#efefef",
          padding: 20,
          paddingBottom: "100px",
        }}
      >
        <Box
          style={{
            maxWidth: 610,
            maxHeight: 475,
            backgroundColor: "white",
          }}
        >
          <Payment onPay={handleSubmit} />
        </Box>
      </Box>
      <SecondPay />
    </>
  );
}

function SecondPay() {
  const [customerId, setCustomerId] = React.useState(undefined);
  function refresh() {
    axios
      .request({
        url: "/test/payment/refresh",
        method: "GET",
      })
      .then((res) => {
        log.warn("res:", res);
      });
  }

  function handleRefresh() {
    refresh();
  }

  function handlePayAgain() {
    axios
      .request({
        url: "/test/payment/pay-again",
        method: "POST",
        data: {
          customerId,
        },
      })
      .then((res) => {
        log.warn("res:", res);
      });
  }

  function handleChange(e) {
    setCustomerId(e.target.value);
  }

  return (
    <Box>
      <Box>Try to pay later:</Box>
      <Button onClick={handleRefresh} variant="contained">
        Refresh
      </Button>
      <input
        onChange={handleChange}
        value={customerId}
        placeholder="stripe customer id"
      />
      <Button onClick={handlePayAgain} variant="contained">
        Pay again
      </Button>
      <Box height={10} />
      <Button
        onClick={() => {
          message.success("Paid successfully!");
        }}
      >
        Test alert box
      </Button>
    </Box>
  );
}

export default PaymentSandbox;
