import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import log from "loglevel";
import Rating from "@material-ui/lab/Rating";
import Modal from "./common/CBModal";
import CBButtonGroup from "./common/CBButtonGroup";
import axios from "axios";
import { useAuth } from "context/auth";
import AvatarSmallB from "./AvatarSmallB";

const styles = (theme) => ({
  root: {},
  rateGrid: {
    justifyContent: "center",
  },
});

function RateModal(props) {
  const { classes } = props;
  const { authToken } = useAuth();
  const [rateValue, setRateValue] = React.useState(null);
  const [application, setApplication] = React.useState(undefined);

  log.warn("rate props:", props);

  async function loadApplication() {
    let res = await axios.request({
      url: `/api/applications/${props.job.winner}`,
      method: "GET",
      headers: {
        Authorization: authToken.token,
      },
    });
    log.info("application:", res.data);

    setApplication(res.data);
  }

  async function rate() {
    //load jobs waiting for winner
    let res = await axios.request({
      url: `/api/jobs/${props.job.id}/rate`,
      method: "PUT",
      headers: {
        Authorization: authToken.token,
      },
      data: {
        rate: rateValue,
      },
    });
    log.info("res:", res.status);
    props.onFinished && props.onFinished();
  }

  function handleCancel() {
    log.warn("handleCancel");
    props.onCanceled && props.onCanceled();
  }

  function handleOK() {
    log.warn("handleOK");
    if (rateValue !== null) {
      rate(rateValue);
    }
  }

  React.useEffect(() => {
    loadApplication();
  }, []); // eslint-disable-line

  return (
    <Modal>
      <Typography variant="h4" color="primary">
        Giv din rating for samarbejdet
      </Typography>
      {application && <AvatarSmallB user={application.applicantId} />}
      <Grid container className={classes.rateGrid}>
        <Box m={1}>
          <Rating
            name="unique-rating"
            value={rateValue}
            onChange={(event, newValue) => {
              console.log("onChange rating");
              event.preventDefault();
              setRateValue(newValue);
            }}
            size="large"
          />
        </Box>
      </Grid>
      <CBButtonGroup onCancel={handleCancel} onOK={handleOK} />
      <Box m={1} />
      <Grid container justifyContent="center">
        <Typography variant="caption">
          Tak fordi du valgte at bruge Bygnetværk!
        </Typography>
      </Grid>
    </Modal>
  );
}

export default withStyles(styles)(RateModal);
