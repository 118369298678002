import React from "react";
import Modal from "@material-ui/core/Modal";
import axios from "../axios";
import { useAuth } from "context/auth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import log from "loglevel";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import JobCard from "./JobCard";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    maxHeight: "90%",
    overflow: "auto",
    marginTop: 20,
    position: "absolute",
    margin: 10,
    padding: 19,
    maxWidth: 800,
    minWidth: 329,
    left: "50%",
    transform: "translate(-50%, 0px)",
    "& .MuiTableCell-root": {
      padding: 6,
    },
  },
  creditBox: {
    width: "100%",
  },
  payButtonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  payButton: {
    minWidth: 300,
  },
  applicantListBox: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  buttonBox: {
    justifyContent: "space-around",
  },
});

function ConfirmWinnerModal(props) {
  const [open, setOpen] = React.useState(false);
  const { classes } = props;
  const [job, setJob] = React.useState(undefined);
  const [application, setApplication] = React.useState([]);
  const { authToken } = useAuth();
  const history = useHistory();

  async function load() {
    //load jobs waiting for winner
    let res = await axios.request({
      url: "/api/applications/self/waitingForWinnerConfirmation",
      method: "GET",
      headers: {
        Authorization: authToken.token,
      },
    });
    log.info("res:", res.data);
    //NOTE just deal with the first one
    const application = res.data[0];
    setApplication(application);

    res = await axios.request({
      url: `/api/jobs/${application.jobId}`,
      method: "GET",
      headers: {
        Authorization: authToken.token,
      },
    });
    log.info("res:", res.data);
    setJob(res.data);

    setOpen(true);
  }

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
  };

  async function handleYes() {
    await axios.request({
      url: `/api/applications/${application._id}/confirmWinner`,
      method: "PUT",
      headers: {
        Authorization: authToken.token,
      },
    });

    message.success("lykkedes!", 5);
    setTimeout(() => {
      history.push("/myapplications");
      window.location.reload();
    }, 2000);
  }

  async function handleNo() {
    await axios.request({
      url: `/api/applications/${application._id}/denyWinner`,
      method: "PUT",
      headers: {
        Authorization: authToken.token,
      },
    });

    message.success("lykkedes!", 5);
    setTimeout(() => {
      history.push("/jobs");
    }, 2000);
  }

  return (
    <>
      <Modal open={open}>
        <form onSubmit={handleSubmit}>
          <Paper className={classes.root}>
            <Grid container>
              <Grid item>
                <Typography variant="h5" color="primary">
                  Vandt du denne udbud?
                </Typography>
                {job && <JobCard {...job} enableRate={false} />}
                <Grid container className={classes.buttonBox}>
                  <Button
                    onClick={handleYes}
                    variant="contained"
                    color="primary"
                  >
                    Ja
                  </Button>
                  <Button
                    onClick={handleNo}
                    variant="contained"
                    color="secondary"
                  >
                    Nej
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Modal>
    </>
  );
}

export default withStyles(styles)(ConfirmWinnerModal);
