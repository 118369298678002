import React, { useState } from "react";
import video from "../images/about.mp4";
import cover from "../images/cover.png";
import { useAuth } from "../context/auth";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import log from "loglevel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { JOB_POSITIONS } from "../constants";
import CBModal from "./common/CBModal";
import JobCard from "./JobCard";
import Terms from "./Terms";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import "../style2.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://bygnetvaerk.dk/">
        Bygnetværk
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  phoneBox: {
    "& .iti--allow-dropdown": {
      width: "100%",
      borderRadius: "4px",
      color: "rgba(0, 0, 0, 0.87)",
      cursor: "text",
      display: "inline-flex",
      position: "relative",
      fontSize: "1rem",
      boxSizing: "border-box",
      alignItems: "center",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "400",
      lineHeight: "1.1876em",
      letterSpacing: "0.00938em",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: "1px",
      borderStyle: "solid",
    },
    "& input": {
      width: "100%",
      boxShadow: "none",
      padding: "18.5px 14px",
      marginLeft: 32,
    },
  },
  box1: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
    "&>div": {
      maxWidth: "468px",
      margin: "18px",
    },
    "&>div>:nth-child(2)": {
      marginTop: 4,
    },
    "&>div>:nth-child(3)": {
      marginTop: 10,
    },
  },
  box5: {
    marginTop: 48,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  titleBox: {
    width: "55%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  title: {
    fontFamily: "Gill Sans Nova Light",
    fontSize: 39,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      fontSize: 30,
      fontWeight: 600,
    },
  },
  body1: {
    fontFamily: "Gill Sans Nova Light",
    marginTop: 24,
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      marginTop: 12,
      fontSize: 20,
    },
  },
  logo: {
    width: 167,
  },
  swiper: {},
  swiperSlide: {
    width: 388,
    // transform: "scale(0.6)",
  },
  videoBox: {
    margin: 10,
  },
  crosual: {
    display: "flex",
    "&>div": {
      maxWidth: (388 + 20) * 1 + 20,
    },
    justifyContent: "center",
  },
});

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Indtast gyldig email").required("Email mangler"),
  password: Yup.string()
    .min(8, "Kodeord skal være mindst 8 tegn langt")
    .required("Kodeord mangler"),
  acceptTerms: Yup.bool().oneOf(
    [true],
    "Du mangler at acceptere forretningsbetingelser",
  ),
});

const SignUp = (props) => {
  const history = useHistory();
  const { setAuthToken } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isReadOpen, setIsReadOpen] = useState(false);
  const [jobCards, setJobCards] = useState([]);
  //use media query to change the number of slides
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [stat, setStat] = useState(undefined);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      cvr: "",
      phone: "",
      acceptTerms: false,
      email: "",
      accountType: "company",
      jobSubscriptions: JOB_POSITIONS.map((e) => e.id),
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsSubmitting(true);

      let result;
      try {
        result = await axios.post("/auth/signup", values);
        // if (result.status === 201) {
        //   setAuthToken(result.data);
        //   history.push("/jobs");
        // }
        setIsSuccess(true);
        console.log(result);
        setTimeout(async () => {
          const result = await axios.post("/auth/login", {
            email: values.email,
            password: values.password,
          });
          setAuthToken(result.data);
          history.push("/jobs");
        }, 3000);
      } catch (error) {
        if (error.response.status === 409) {
          setErrorMessage("Email er allerede oprettet - brug login i stedet.");
        } else if (error.response.status === 404) {
          console.error(error);
          setErrorMessage(
            "CVR kunne ikke findes i CVR register. Tjek venligst CVR.",
          );
        } else {
          setErrorMessage(
            "Der opstod en fejl ved oprettelse. Prøv igen senere, eller kontakt Bygnetværk.",
          );
        }
      }
      setIsSubmitting(false);
    },
  });
  const {
    touched,
    errors,
    handleChange: onChange,
    handleBlur: onBlur,
    handleSubmit,
    values,
  } = formik;
  const { classes } = props;

  log.warn("key:", values, errors);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    // const input = document.querySelector("#phone");
    // intlTelInput(input, {
    //   // any initialisation options go here
    // });
  }, []);

  function handleRead() {
    setIsReadOpen(true);
  }

  async function fetchData() {
    try {
      const response = await axios.get("/api/jobs?status=Active");
      setJobCards(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  async function loadStat() {
    try {
      const response = await axios.get("/api/stat");
      setStat(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  React.useEffect(() => {
    fetchData();
    loadStat();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!isSuccess && (
        <>
          <Typography variant="h6" align="center" style={{ marginTop: 20 }}>
            Udbuds- og tilbudsportal for professionelle - <br />
            og deres kunder
          </Typography>
          {stat && (
            <>
              <Typography variant="body2" align="center">
                Der er i øjeblikket
              </Typography>
              <Typography variant="body2" align="center">
                {stat.openJobCount} Offentlige udbud
              </Typography>
              <Typography variant="body2" align="center">
                {stat.userCount} Brugere
              </Typography>
            </>
          )}
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Opret din gæsteprofil
              </Typography>
              <Typography component="h1" variant="body1">
                og se alle udbud
              </Typography>
              <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={values.email}
                      helperText={touched.email ? errors.email : ""}
                      error={touched.email && Boolean(errors.email)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Kodeord"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      onBlur={onBlur}
                      onChange={onChange}
                      helperText={touched.password ? errors.password : ""}
                      error={touched.password && Boolean(errors.password)}
                      value={values.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="acceptTerms"
                            id="acceptTerms"
                            onBlur={onBlur}
                            onChange={onChange}
                            checked={values.acceptTerms}
                          />
                        }
                        label="Jeg har forstået og accepteret Bygnetværk forretningsbetingelser"
                        style={{
                          marginRight: 0,
                        }}
                      />
                      <Box alignSelf="end">
                        <a href onClick={handleRead}>
                          Læs
                        </a>
                      </Box>
                    </Box>
                    <FormHelperText error={Boolean(errors.acceptTerms)}>
                      {touched.acceptTerms ? errors.acceptTerms : ""}
                    </FormHelperText>
                  </Grid>
                </Grid>

                <Typography variant="subtitle2" color="error">
                  {errorMessage}
                </Typography>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {isSubmitting && <CircularProgress color="white" />}
                  {!isSubmitting && "Opret bruger"}
                </Button>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link href="/login" variant="h5">
                      Har du allerede en konto? Log ind her
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>

          <Grid
            container
            direction="row"
            justify="center"
            className={classes.videoBox}
          >
            <Grid item xs={12} sm={6}>
              <video
                style={{ width: "100%" }}
                controls
                src={video}
                //cover
                poster={cover}
              />
            </Grid>
          </Grid>
          <Box className={classes.crosual}>
            <Box>
              <Swiper
                slidesPerView={isMobile ? "1.1" : 1}
                spaceBetween={0}
                autoplay={true}
                navigation={false}
                pagination={true}
                modules={[Autoplay, Pagination]}
                className={classes.swiper}
              >
                {jobCards.map((jobCard) => (
                  <SwiperSlide className={classes.swiperSlide}>
                    <JobCard
                      key={jobCard._id}
                      {...jobCard}
                      enableRate={false}
                      disableClick={true}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
          <Box mt={5}>
            <Copyright />
          </Box>
        </>
      )}
      {isSuccess && (
        <Box
          style={{
            padding: 40,
          }}
        >
          <Typography variant="h5" align="center" color="primary">
            Tilmelding lykkes!
          </Typography>
          <Typography variant="subtitle2" align="center">
            Vil omdirigere til jobsiden...
          </Typography>
        </Box>
      )}
      {isReadOpen && (
        <CBModal onClose={() => setIsReadOpen(false)}>
          <Box
            height="70vh"
            style={{
              overflow: "auto",
            }}
          >
            <Terms />
          </Box>
        </CBModal>
      )}
    </>
  );
};

export default withStyles(styles)(SignUp);
