import React, { useState, useEffect } from "react";
//import ListSubheader from "@material-ui/core/ListSubheader";
import axios from "../axios";
import JobCard from "components/JobCard";
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "context/auth";
import {
  Grid,
  Button,
  Box,
  Paper,
  Typography,
  Avatar,
  TextField,
  InputLabel,
  Select,
  FormControl,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import turtle from "../images/turtle.jpg";
import CBModal from "./common/CBModal";
import log from "loglevel";
import { message } from "antd";
import FormGroup from "@material-ui/core/FormGroup";
import ServicesSelector from "./common/ServicesSelector";

const styles = (theme) => ({
  submit: {
    margin: theme.spacing(2),
  },
  paper1: {
    position: "absolute",
    bottom: 0,
    right: 0,
    margin: theme.spacing(4),
    width: "357px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "14px 15px",
  },
  box1: {
    display: "flex",
    justifyContent: "space-between",
  },
  box2: {
    // marginTop: 12,
  },
  button1: {
    marginTop: "13px",
    width: "80%",
  },
  avatar1: {
    margin: 4,
    width: "59px",
    height: "47px",
  },
  box3: {
    position: "absolute",
    top: "0",
    right: "0",
    width: "30px",
    height: "30px",
    backgroundColor: "#3531D8",
    color: "white",
    fontSize: "29px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginTop: "-13px",
    marginRight: "-13px",
    cursor: "pointer",
  },
  typography1: {
    marginTop: 12,
  },
  paper2: {
    width: 480,
    display: "flex",
    flexDirection: "column",
  },
  text1: {
    width: "100%",
    marginTop: "12px",
  },
  button2: {
    marginTop: "14px",
    width: "69%",
    alignSelf: "center",
  },
  box11: {
    display: "flex",
    justifyContent: "end",
    padding: "5px 20px",
    gap: "10px",
    // for mobile
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      flexDirection: "column",
      padding: "5px",
      alignItems: "center",
    },
  },
  isFree: {
    marginTop: 10,
    minWidth: 250,
    "& .MuiFormLabel-root": {
      marginTop: "-12px",
    },
  },
});

function JobFeed(props) {
  const history = useHistory();
  const { authToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [jobCards, setJobCards] = useState([]);
  const { classes } = props;
  const [isFeedbackNeeded, setIsFeedbackNeeded] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [content, setContent] = useState("");
  const [isFree, setIsFree] = useState("all");
  const [position, setPosition] = useState([]);

  async function fetchData() {
    try {
      const response = await axios.get(
        `/api/jobs?status=Active&isFree=${isFree}&positions=${position
          .filter((p) => p !== -1)
          .join(",")}`,
        {
          headers: { Authorization: authToken?.token },
        },
      );
      setJobCards(response.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const gotoCreateJobBtn = (props) => {
    history.push("/createtask");
  };

  async function fetchFeedbackData() {
    try {
      const response = await axios.get("/api/feedback", {
        headers: { Authorization: authToken?.token },
      });
      log.warn("/api/feedback:", response.data);
      setIsFeedbackNeeded(response.data.isFeedbackNeeded);
    } catch (err) {
      console.error(err);
    }
  }
  // feedback
  useEffect(() => {
    fetchFeedbackData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData();
  }, [isFree, position]); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleSendFeedback() {
    await axios({
      method: "post",
      url: "/api/feedback",
      headers: { Authorization: authToken?.token },
      data: {
        content,
      },
    });
    setIsFeedbackOpen(false);
    message.success("Feedback sendt!", 5);
  }

  async function handleDeleteFeedback() {
    await axios({
      method: "delete",
      url: "/api/feedback",
      headers: { Authorization: authToken?.token },
    });
    setIsFeedbackOpen(false);
    setIsFeedbackNeeded(false);
  }

  const spinner = (
    <Grid container justify="center">
      <ClipLoader size={150} loading={isLoading} />
    </Grid>
  );

  const jobCardElements = jobCards.map((jobCard) => (
    <JobCard key={jobCard._id} {...jobCard} enableRate={false} />
  ));

  const jobCardsOrSpinner = isLoading ? spinner : jobCardElements;

  function handleIsFreeClick(e) {
    log.warn("isFree:", e.target.value);
    setIsFree(e.target.value);
  }

  function handlePositionChange(e) {
    log.warn("handle select changee.target:", e.target);
    if (e.target.value.some((v) => v === -1)) {
      log.warn("all");
      setPosition([]);
      return;
    }
    setPosition(e.target.value);
  }

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant="h6" align="center" style={{ marginTop: 20 }}>
            Udbuds- og tilbudsportal for professionelle - <br />
            og deres kunder
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box11}>
            <ServicesSelector
              handlePositionChange={handlePositionChange}
              position={position}
            />
            <FormGroup
              row
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl variant="outlined" className={classes.isFree}>
                <InputLabel htmlFor="age-native-simple">Vælg udbud</InputLabel>
                <Select
                  native
                  value={isFree}
                  onChange={handleIsFreeClick}
                  inputProps={{
                    name: "isFree",
                    id: "isFree",
                  }}
                >
                  <option value={"all"}>Alle udbud</option>
                  <option value={false}>Udbud med abonnement</option>
                  <option value={true}>Udbud uden abonnement</option>
                </Select>
              </FormControl>
            </FormGroup>
          </Box>
        </Grid>
        <Grid container>{jobCardsOrSpinner}</Grid>
        <Grid container justify="center">
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={gotoCreateJobBtn}
          >
            Opret Udbud
          </Button>
        </Grid>
      </Grid>
      {isFeedbackNeeded && (
        <Paper className={classes.paper1} elevation={4}>
          <Box onClick={handleDeleteFeedback} className={classes.box3}>
            ×
          </Box>
          <Box className={classes.box1}>
            <Box className={classes.box2}>
              <Typography variant="h6">Giv os din mening</Typography>
              <Typography className={classes.typography1} variant="body1">
                Giv feedback så fjerner vi et kilo skrald fra verdenshavene.
              </Typography>
            </Box>
            <Avatar variant="square" className={classes.avatar1} src={turtle} />
          </Box>
          <Button
            onClick={() => {
              setIsFeedbackOpen(true);
              setIsFeedbackNeeded(false);
            }}
            className={classes.button1}
            variant="contained"
            color="primary"
          >
            GIV FEEDBACK
          </Button>
        </Paper>
      )}
      {isFeedbackOpen && (
        <CBModal>
          <Paper className={classes.paper2} elevation="0">
            <Box onClick={handleDeleteFeedback} className={classes.box3}>
              ×
            </Box>
            <Typography variant="h5">Giv os din feedback</Typography>
            <Typography variant="body1">
              Giv os din feedback, og vi fjerner 1 kg skrald fra verdenshavene
            </Typography>
            <TextField
              inputProps={{
                maxLength: 1024,
              }}
              multiline
              className={classes.text1}
              variant="outlined"
              rows="10"
              onChange={(e) => setContent(e.target.value)}
            />
            <Button
              onClick={handleSendFeedback}
              className={classes.button2}
              variant="contained"
              color="primary"
            >
              SEND FEEDBACK
            </Button>
          </Paper>
        </CBModal>
      )}
    </>
  );
}
export default withStyles(styles)(JobFeed);
