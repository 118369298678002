import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import AvatarEditor from "react-avatar-editor";
import Slider from "@material-ui/core/Slider";

const style = (theme) => ({
  box1: {
    backgroundColor: "#F2F2F2",
    marginBottom: theme.spacing(1),
  },
  box2: {
    marginBottom: theme.spacing(0),
  },
  file: {
    visibility: "hidden",
    position: "absolute",
  },
  tip: {
    marginBottom: theme.spacing(1),
  },
});

/*
 * check if the rect data is wront, NaN, then return a default one
 */
function getRect(rect) {
  if (
    isNaN(rect.x) ||
    isNaN(rect.y) ||
    isNaN(rect.width) ||
    isNaN(rect.height)
  ) {
    return {
      x: 0,
      y: 0,
      width: 1,
      height: 1,
    };
  } else {
    return rect;
  }
}

function Photo(props) {
  const { classes, imageOriginal } = props;
  const [image, setImage] = React.useState(imageOriginal);
  const [scale, setScale] = React.useState(props.scale);
  const [slide, setSlide] = React.useState(props.scale * 50);
  const [dataUrl, setDataUrl] = React.useState(imageOriginal);
  const [position, setPosition] = React.useState(props.position);
  const fileRef = React.useRef(null);
  const editorRef = React.useRef(null);

  React.useEffect(() => {
    props.setPhotoData({
      dataUrl: imageOriginal,
      rect: getRect({}),
      position,
      scale,
    });
  }, []); // eslint-disable-line

  const handleNewImage = (e) => {
    console.log("handleNewImage:", e.target.files);
    setImage(e.target.files[0]);
    let file = e.target.files[0];
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = ((theFile) => {
      return async (e) => {
        const dataUrl = e.target.result;
        //console.log("dataURL:", dataUrl);
        const rect = getRect(editorRef.current.getCroppingRect());
        setDataUrl(dataUrl);
        props.setPhotoData({
          dataUrl,
          position,
          scale,
          rect,
        });
      };
    })(file);

    // Read in the image file as a data URL.
    reader.readAsDataURL(file);
  };

  function handleScaleChange(_e, newValue) {
    setSlide(newValue);
    setScale(slide / 50);
    const rect = getRect(editorRef.current.getCroppingRect());
    if (!rect.width) {
      throw Error();
    }
    props.setPhotoData({
      dataUrl,
      rect,
      scale,
      position,
    });
  }

  function handleChangeCommitted(_e, newValue) {
    setSlide(newValue);
    setScale(slide / 50);
    props.setPhotoData({
      dataUrl,
      rect: getRect(editorRef.current.getCroppingRect()),
      scale,
      position,
    });
  }

  function handleClick() {
    fileRef.current.click();
  }

  function handlePositionChange(p) {
    setPosition(p);
    props.setPhotoData({
      dataUrl,
      rect: getRect(editorRef.current.getCroppingRect()),
      scale,
      position,
    });
  }

  return (
    <Paper elevation={0}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.box1}
      >
        <AvatarEditor
          ref={editorRef}
          image={image}
          width={200}
          height={200}
          border={50}
          color={[200, 200, 200, 0.8]} // RGBA
          scale={scale}
          rotate={0}
          position={position}
          onPositionChange={handlePositionChange}
        />
      </Grid>
      <Grid xs={3} className={classes.box2}>
        <Slider
          value={slide}
          min={1}
          max={100}
          onChangeCommitted={handleChangeCommitted}
          onChange={handleScaleChange}
        />
      </Grid>
      <Typography className={classes.tip}>Træk og slip og brug zoom</Typography>
      <Button onClick={handleClick} color="primary" variant="outlined">
        Upload et nyt billede
      </Button>
      <input
        ref={fileRef}
        className={classes.file}
        name="newImage"
        type="file"
        onChange={handleNewImage}
        onDrop={handleNewImage}
      />
    </Paper>
  );
}

export default withStyles(style)(Photo);
