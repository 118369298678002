import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import "antd/dist/antd.css";
import Paper from "@material-ui/core/Paper";
import { InboxOutlined } from "@ant-design/icons";
import axios from "../axios";
import { useAuth } from "../context/auth";
import { useHistory } from "react-router-dom";
import { JOB_POSITIONS, BUDGETS, TASK_CRITERIES } from "../constants";
import log from "loglevel";
import { useDropzone } from "react-dropzone";
import Box from "@material-ui/core/Box";
import { AttachFile, Delete } from "@material-ui/icons";
import { message, DatePicker, TimePicker } from "antd";
import utils from "../utils";
import {
  IconButton,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import moment from "moment";
import CBModal from "./common/CBModal";
import SignUpTwoFactor from "./SignUpTwoFactor";
import { Alert, Space } from "antd";
moment.locales("da");

const THE_BLUE = "#0889F8";

const UPLOAD_MAX_SIZE = 31457280; //30M

const styles = (theme) => ({
  container: {
    flexWrap: "nowrap",
  },
  paper: {
    width: "80%",
    maxWidth: 800,
    margin: theme.spacing(3),
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
    },
  },
  root: {
    flexWrap: "nowrap",
    width: "100%",
    padding: theme.spacing(4),
    "& .blue": {
      color: "#2183FA",
    },
    "& .title": {
      display: "flex",
      justifyContent: "center",
      margin: theme.spacing(2.5),
    },
    "& .label": {
      color: THE_BLUE,
      display: "block",
      margin: theme.spacing(1, 0),
      fontWeight: 500,
    },
    "& .description": {
      color: THE_BLUE,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    "& .description-upload": {
      color: THE_BLUE,
    },
    "& .strong": {
      fontWeight: 500,
    },
    "& .underline": {
      textDecoration: "underline",
    },
    "& .ant-upload.ant-upload-drag": {
      border: "2px dashed #d9d9d9",
    },
  },
  field: {
    marginBottom: theme.spacing(2.5),
  },
  input: {
    width: "100%",
  },
  inputContainer: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: "100%",
    marginBottom: theme.spacing(2.5),
  },
  uppercase: {
    textTransform: "uppercase",
  },
  priorities: {
    margin: theme.spacing(1),
    minHeight: 80,
  },
  priority: {
    margin: theme.spacing(0.5),
  },
  submit: {
    margin: theme.spacing(2),
    width: 344,
  },
  bitTitle: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
    margin: "0",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
  },
  uploadBox: {
    padding: theme.spacing(4, 0),
    border: "2px dashed #d9d9d9",
    background: "#fafafa",
    cursor: "pointer",
    transition: "border-color 0.3s",
    borderRadius: "2px",
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    "&:hover": {
      borderColor: "#40a9ff",
    },
    "& .anticon": {
      color: "#40a9ff",
      fontSize: "48px",
    },
  },
  filesBox: {
    justifyContent: "flex-start",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  fileBox: {
    alignItems: "center",
  },
  deleteIcon: {
    marginLeft: theme.spacing(1),
  },
  dropzone: {
    "&:focus": {
      outline: "none",
    },
  },
  submitting: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1000,
  },
  box5: {
    display: "flex",
    gap: "10px",
  },
});

function CreateTask(props) {
  const { authToken } = useAuth();
  const history = useHistory();
  const [successModal, setSuccessModal] = useState(false);
  const [jobLimitation, setJobLimitation] = useState("pending"); //pending, pass, fail
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [adminMode, setAdminMode] = useState(false);
  const { task } = props;
  log.warn("xxxtask:", task);

  const formik = useFormik({
    initialValues: {
      location: (task && task.location) || "",
      description: (task && task.description) || "",
      priorities: (task && task.prioritiesSelected) || [],
      title: (task && task.title) || "",
      position: (task && task.position) || 0,
      budget: (task && task.budget) || 0,
      deadline:
        (task &&
          task.deadline &&
          moment(task.deadline).format("yyyy-MM-DD HH:mm")) ||
        "",
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values, dag) => {
      log.warn("dag:", dag);
      const { setSubmitting } = dag;
      setIsSubmitting(true);
      setSubmitting(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      //REVISE Mon Jun 15 09:35:06 CST 2020
      //cuz file type, we need to use formData to upload fields.
      const form = document.getElementById("theForm");
      log.debug("The from:", form);
      const formData = new FormData(form);
      console.log(formData);
      if (task && task._id) {
        log.warn("edit task:", task._id);
        await axios
          .request({
            url: `/api/jobs/${task._id}/edit`,
            method: "PATCH",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: authToken.token,
            },
          })
          .then((res) => {
            setSuccessModal(true);
            setTimeout(() => {
              setSuccessModal(false);
            }, 1800);
            setTimeout(() => {
              history.push(`/myjobs/${task._id}`);
              setSubmitting(false);
            }, 2000);
          })
          .catch((e) => {
            log.error("e:", e);
            if (e.response && e.response.data && e.response.data.message) {
              message.error(e.response.data.message, 10);
            }
          });
      } else {
        log.warn("create task");
        await axios
          .request({
            url: "/api/jobs",
            method: "POST",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: authToken.token,
            },
          })
          .then((res) => {
            setSuccessModal(true);
            setTimeout(() => {
              setSuccessModal(false);
            }, 1800);
            setTimeout(() => {
              history.push("/jobs");
              setSubmitting(false);
            }, 2000);
          })
          .catch((e) => {
            log.error("e:", e);
            if (e.response && e.response.data && e.response.data.message) {
              message.error(e.response.data.message, 10);
            }
          });
      }
    },
  });
  log.warn("formik:", formik);
  const {
    values,
    errors,
    touched,
    handleChange: onChange,
    handleBlur: onBlur,
    handleSubmit,
    setFieldTouched,
  } = formik;

  const { classes } = props;
  const [files, setFiles] = React.useState([]);
  //I'm not sure this is a good way to implement delete file function, there is
  //a little problem with dropzone lib for doing this. I copied the code from
  //here: https://github.com/react-dropzone/react-dropzone/issues/805
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files],
  );
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: ".pdf,.doc,.docx",
    minSize: 0,
    maxSize: UPLOAD_MAX_SIZE, //30M
    onDrop,
  });

  const [chosenPriorities, setChosenPriorities] = React.useState(
    (task && task.prioritiesSelected) || [],
  );
  log.warn("chosen:", chosenPriorities);

  function handleDescriptionChange(e) {
    if (e.target.value.length === 1) {
      //this is just the first key strike in the field, then, ignore the
      //touch
    } else {
      setFieldTouched("description", true);
    }
    onChange(e);
  }

  function handleTitleChange(e) {
    if (e.target.value.length === 1) {
      //this is just the first key strike in the field, then, ignore the
      //touch
    } else {
      setFieldTouched("title", true);
    }
    onChange(e);
  }

  const handleClose = () => {
    setSuccessModal(false);
  };

  function checkJobLimitation() {
    axios
      .request({
        url: "/api/jobs/self/checkJobLimitation",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authToken.token,
        },
      })
      .then(() => {
        setJobLimitation("pass");
      })
      .catch((e) => {
        log.error("e:", e);
        if (
          utils.parseAxiosError(e).message ===
          "Hver bruger kan kun oprette to udbud om ugen"
        ) {
          setJobLimitation("fail");
        }
      });
  }

  async function checkGuest() {
    const res = await axios.request({
      url: "/api/users",
      headers: {
        Authorization: authToken.token,
      },
    });
    log.warn("loaded user:", res);
    if (res.data.phone) {
      log.warn("normal user");

      // check the admin status
      if (task && task._id) {
        log.warn("check edit mode:", task.createdBy, res.data._id);
        if (task.createdBy !== res.data._id) {
          log.warn("admin mode");
          setAdminMode(true);
        }
      }
    } else {
      log.warn("guest user");
      setIsSignUpOpen(true);
    }
  }

  function handleSignUpSuccess() {
    setIsSignUpOpen(false);
  }

  React.useEffect(() => {
    checkGuest();
    checkJobLimitation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [date, setDate] = React.useState(
    (task && task.deadline && moment(task.deadline).format("yyyy-MM-DD")) || "",
  );
  const [time, setTime] = React.useState(
    (task && task.deadline && moment(task.deadline).format("HH:mm")) || "",
  );
  const [deadline, setDeadline] = React.useState(
    (task &&
      task.deadline &&
      moment(task.deadline).format("yyyy-MM-DD HH:mm")) ||
      "",
  );

  function handleDateChange(date, dateString) {
    setDate(dateString);
    const deadline = `${dateString} ${time}`;
    setDeadline(deadline);
    // /*
    //  * To let Formik know and update this field
    //  */
    const e = {
      target: {
        id: "deadline",
        value: deadline,
      },
    };
    onChange(e);
  }

  function handleTimeChange(time, timeString) {
    setTime(timeString);
    const deadline = `${date} ${timeString}`;
    setDeadline(deadline);
    // /*
    //  * To let Formik know and update this field
    //  */
    const e = {
      target: {
        id: "deadline",
        value: deadline,
      },
    };
    onChange(e);
  }

  function handleSelectChange(e) {
    log.warn("handle select changee.target:", e.target);
    if (e.target.value.some((v) => v === undefined)) {
      log.warn("some empty");
      return;
    }
    setFieldTouched("priorities", true);
    setChosenPriorities(e.target.value);
    const newPriorities = e.target.value;
    const eFack2 = {
      target: {
        id: "priorities",
        value: newPriorities,
      },
    };
    onChange(eFack2);
  }

  // const [selectedDate, setSelectedDate] = React.useState(new Date("2014-08-18T21:11:54"));

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  return (
    <>
      <Grid container className={classes.container} justify="center">
        <Paper className={classes.paper} elevation={0}>
          <form id="theForm" onSubmit={handleSubmit} noValidate>
            <Grid direction="column" container className={classes.root}>
              <Grid item className="title">
                <Typography className="blue" color="primary" variant="h4">
                  {task ? "Rediger" : "Opret udbud"}
                </Typography>
              </Grid>
              {jobLimitation === "pass" && (
                <>
                  <Grid
                    container
                    spacing={2}
                    className={classes.inputContainer}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        className={`label ${classes.uppercase}`}
                        htmlFor="title"
                        component="label"
                      >
                        Titel
                      </Typography>
                      <TextField
                        id="title"
                        name="title"
                        variant="outlined"
                        type="text"
                        placeholder="Indtast en udbudtitel"
                        onChange={handleTitleChange}
                        onBlur={onBlur}
                        helperText={touched.title ? errors.title : ""}
                        error={touched.title && Boolean(errors.title)}
                        className={classes.input}
                        value={values.title}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        className={`label ${classes.uppercase}`}
                        htmlFor="position"
                        component="label"
                      >
                        Faggruppe
                      </Typography>
                      <Select
                        id="position"
                        name="position"
                        variant="outlined"
                        className={classes.input}
                        onChange={onChange}
                        value={values.position}
                      >
                        {JOB_POSITIONS.map((job) => (
                          <MenuItem key={job.id} value={job.id}>
                            {job.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.inputContainer}
                  >
                    <Grid xs={12} sm={6} item>
                      <Typography
                        variant="body1"
                        className={`label ${classes.uppercase}`}
                        htmlFor="location"
                        component="label"
                      >
                        Arbejdssted
                      </Typography>
                      <TextField
                        id="location"
                        name="location"
                        variant="outlined"
                        type="text"
                        placeholder="Indtast venligst din arbejdsplads"
                        onChange={onChange}
                        onBlur={onBlur}
                        helperText={touched.location ? errors.location : ""}
                        error={touched.location && Boolean(errors.location)}
                        className={classes.input}
                        value={values.location}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <Typography
                        variant="body1"
                        className={`label ${classes.uppercase}`}
                        htmlFor="budget"
                        component="label"
                      >
                        Budget i DKK
                      </Typography>

                      <Select
                        id="budget"
                        name="budget"
                        variant="outlined"
                        className={classes.input}
                        onChange={onChange}
                        value={values.budget}
                      >
                        {BUDGETS.map((b) => (
                          <MenuItem key={b.id} value={b.id}>
                            {b.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.field}>
                    <Typography
                      variant="h6"
                      className={`label ${classes.uppercase} ${classes.bitTitle}`}
                      htmlFor="description"
                      component="label"
                    >
                      Kort om udbuddet
                    </Typography>
                    <Typography
                      variant="body2"
                      className="description"
                      component="p"
                    >
                      Skriv en kort besked om udbuddet -{" "}
                      <span className="strong">
                        kun prækvalificerede firmaer vil kunne se det fulde
                        udbudsmateriale
                      </span>
                    </Typography>
                    <TextField
                      id="description"
                      name="description"
                      multiline
                      rows={4}
                      variant="outlined"
                      className={classes.textField}
                      onChange={handleDescriptionChange}
                      onBlur={onBlur}
                      helperText={touched.description ? errors.description : ""}
                      error={touched.description && Boolean(errors.description)}
                      placeholder="Indtast venligst kort om udbuddet"
                      value={values.description}
                    />
                  </Grid>
                  <Grid item className={classes.field}>
                    <Typography
                      variant="h6"
                      className={`label ${classes.uppercase} ${classes.bitTitle}`}
                      component="label"
                    >
                      Udvælgelseskriterier
                    </Typography>
                    <Typography
                      variant="body2"
                      className="description"
                      component="label"
                    >
                      Vælg 2 kriterier, der er vigtige for dig og som du vil
                      anvende i forhold til at foretage en senere udvælgelse af
                      dem, der anmoder om at blive prækvalificeret til at afgive
                      tilbud
                    </Typography>
                    <Typography
                      variant="body2"
                      className="description"
                      component="p"
                    >
                      Valgte kriterier
                    </Typography>
                    <Select
                      fullWidth
                      defaultValue={chosenPriorities}
                      id="priorities-selector"
                      variant="outlined"
                      onChange={handleSelectChange}
                      value={chosenPriorities}
                      multiple
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <>
                              <Chip
                                color="primary"
                                className={`${classes.priority} ${classes.chosen}`}
                                key={value}
                                label={TASK_CRITERIES.findNameByValue(value)}
                              />
                              <input
                                id="prioritiesSelected"
                                name="prioritiesSelected"
                                type="hidden"
                                value={value}
                              />
                            </>
                          ))}
                        </div>
                      )}
                    >
                      <Box p={1}>
                        <Typography variant="caption">
                          klik et vilkårligt sted uden for listen for at afvise
                        </Typography>
                      </Box>
                      {TASK_CRITERIES.map((groups) => {
                        const header = (
                          <ListSubheader>{groups.group_name}</ListSubheader>
                        );
                        const list = groups.items.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ));
                        return [header, list];
                      })}
                    </Select>
                    <p className={classes.error}>
                      {touched.priorities ? errors.priorities : ""}
                    </p>
                  </Grid>
                  <Grid item className={classes.field}>
                    <Typography
                      variant="h6"
                      className={`label ${classes.bitTitle}`}
                      component="label"
                    >
                      Frist for anmodninger
                    </Typography>
                    <Typography
                      variant="body2"
                      htmlFor={`label  ${classes.bitTitle}`}
                      className={`label  ${classes.bitTitle}`}
                      component="p"
                    >
                      Her vælger du deadline ( seneste dato og tid ) for
                      modtagelsen af anmodninger fra tilbudsgivere - bemærk at
                      du som ordregiver ikke bør foretage udvælgelsen før
                      deadline ( dato og tid ) men afvente at alle interessede
                      tilbudsgivere har haft mulighed for at anmode om at blive
                      udvalgt.
                    </Typography>
                    <Box className={classes.box5}>
                      <DatePicker
                        onChange={handleDateChange}
                        size="large"
                        name="date"
                        placeholder="Vælg dato"
                        defaultValue={
                          (task && task.deadline && moment(task.deadline)) ||
                          undefined
                        }
                      />
                      <TimePicker
                        onChange={handleTimeChange}
                        size="large"
                        name="time"
                        format="HH:mm"
                        placeholder="Vælg tid"
                        defaultValue={
                          (task && task.deadline && moment(task.deadline)) ||
                          undefined
                        }
                      />
                    </Box>
                    <p className={classes.error}>
                      {touched.deadline ? errors.deadline : ""}
                    </p>
                    <input
                      id="deadline"
                      name="deadline"
                      type="hidden"
                      value={deadline}
                    />
                  </Grid>
                  <Grid item className={classes.field}>
                    <Typography
                      variant="h6"
                      className={`label ${classes.uppercase} ${classes.bitTitle}`}
                      component="label"
                    >
                      Udbudsmaterialet på PDF/Word
                    </Typography>
                    {task && task.fileName && (
                      <Typography
                        style={{ display: "flex" }}
                        variant="body1"
                        className="description"
                      >
                        <AttachFile style={{ width: 20 }} /> {task.fileName}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      htmlFor="description"
                      className="description"
                      component="p"
                    >
                      Upload her dit udbudsmateriale - det kan være en
                      beskrivelse, skitse, foto, tilbudsliste eller komplet
                      udbudsmateriale - vi hjælper dig gerne med udbarbejdelsen
                      af dit udbudsmateriale som bør foreligge før deadline for
                      anmodninger (ovenfor).
                    </Typography>
                    <Box className={classes.uploadBox}>
                      <Box {...getRootProps({ className: classes.dropzone })}>
                        <input name="file" {...getInputProps()} />
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <Typography
                          className="description-upload"
                          variant="body2"
                        >
                          Træk og slip filer her
                        </Typography>
                        <Typography
                          className="description-upload"
                          variant="body2"
                        >
                          eller
                        </Typography>
                        <Typography
                          className="description-upload underline"
                          variant="body2"
                        >
                          Gennemse dine filer
                        </Typography>
                      </Box>
                    </Box>
                    <Grid container className={classes.filesBox}>
                      {files.map((file) => (
                        <Grid item>
                          <Grid container className={classes.fileBox}>
                            <Grid item>
                              <AttachFile />
                            </Grid>
                            <Grid item>
                              {file.path}({file.size}bytes)
                            </Grid>
                            <Grid item>
                              <IconButton className={classes.deleteIcon}>
                                <Delete onClick={() => setFiles([])} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Box>
                      {fileRejections.length > 0 &&
                        fileRejections[0].errors.some(
                          (e) => e.code === "file-too-large",
                        ) && (
                          <Typography color="secondary">
                            filen er for stor
                          </Typography>
                        )}
                    </Box>
                  </Grid>
                  {/*

                <Grid item>
                  <input name="file" type="file" />
                </Grid>
                */}
                  <Grid item className="title">
                    <Button
                      className={classes.submit}
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      data-testid="create-button"
                      disabled={isSubmitting}
                    >
                      {task ? "GEM" : "OPRET UDBUD"}
                    </Button>
                  </Grid>
                </>
              )}
              {jobLimitation === "fail" && (
                <Box>
                  <Typography variant="h6">Max 2 udbud om ugen</Typography>
                  <Typography variant="body1">
                    Der kan maximalt oprettes 2 udbud om ugen ved Bygnetværk.
                  </Typography>
                  <Typography variant="body1">
                    Du kan oprette dine 2 næste udbud{" "}
                    {moment()
                      .add(1, "weeks")
                      .startOf("isoweek")
                      .format("dddd [d.] D MMMM")}
                    .
                  </Typography>
                </Box>
              )}
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Dialog open={successModal} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <CheckCircleIcon
            style={{
              color: "#2183FA",
              fontSize: 50,
            }}
          />
          <h5>Tak!</h5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              marginTop: "-12px",
            }}
          >
            {!task && (
              <>
                Dit udbud er nu oprettet. Du vil nu blive omdirigeret til
                udbudsoversigten.
              </>
            )}
            {task && (
              <>
                Dit udbud er nu ændret. Du vil nu blive omdirigeret til
                udbudsoversigten.
              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {isSubmitting && <LinearProgress className={classes.submitting} />}

      {isSignUpOpen && (
        <Box>
          <CBModal>
            <SignUpTwoFactor mode="employer" onSuccess={handleSignUpSuccess} />
          </CBModal>
        </Box>
      )}
      {adminMode && (
        <Space
          id="admin-mode"
          direction="vertical"
          style={{ width: "100%", top: "0px", position: "fixed" }}
        >
          <Alert
            message="Du redigerer dette som administrator"
            banner
            closable
          />
        </Space>
      )}
    </>
  );
}

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Udbudstitel mangler")
    .max(50, "Udbudstitel er for lang"),
  location: Yup.string()
    .required("Arbejdssted mangler")
    .max(50, "Arbejdssted er for lang"),
  description: Yup.string()
    .required("Kort om udbuddet mangler")
    .test("len of description", "Kort om udbuddet er for langt", (val) => {
      if (val) {
        return val.split(/\s+/).length < 25 && val.length < 300;
      }
    }),
  deadline: Yup.string()
    .required("Vælg venligst frist for anmodninger")
    .test(
      "Vælg venligst frist for anmodninger",
      "Vælg venligst frist for anmodninger",
      (val) => {
        if (val) {
          return val.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/);
        }
      },
    ),
  priorities: Yup.array()
    .min(2, "vælg flere kriterier")
    .max(2, "Du kan ikke vælge mere end to kriterier"),
});

export default withStyles(styles)(CreateTask);
