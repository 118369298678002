import React, { useState } from "react";
import HomeIcon from "@material-ui/icons/Home";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import logo from "../images/logoby.jpeg";
import logoNoWords from "../images/logonowords.png";
import MenuIcon from "@material-ui/icons/Menu";
import axios from "../axios";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InfoIcon from "@material-ui/icons/Info";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { Slide } from "@material-ui/core/";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import { useAuth } from "../context/auth";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import log from "loglevel";
import jwt from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  container: {
    maxWidth: 1280,
    flexWrap: "'nowrap",
  },
  menuBox: {
    height: "100%",
    flexWrap: "'nowrap",
  },
  link: {
    color: "white",
  },
  linkBox: {
    marginRight: theme.spacing(1.5),
  },
  icon: {
    margin: theme.spacing(0, 1),
    marginTop: -2,
  },
  logoutBox: {
    height: "100%",
  },
  logoBox: {
    height: "100%",
  },
  logo: {
    width: 32,
    height: 32,
    fontSize: 22,
    marginTop: -theme.spacing(1),
  },
  logo2: {
    height: 63,
    width: 82,
    backgroundSize: "cover",
    backgroundImage: `url(${logo})`,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${logoNoWords})`,
      backgroundColor: "white",
      backgroundSize: 41,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: 4,
      backgroundPositionY: 10,
    },
  },
  brand: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  menu: {
    color: "white",
  },
  menuList: {
    paddingLeft: 5,
    paddingTop: 23,
    paddingRight: 20,
  },
  logoNoWords: {
    flexDirecton: "row",
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function BackToTop(props) {
  const { authToken, clearAuthToken } = useAuth();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMouseEnter(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMouseLeave() {
    setAnchorEl(null);
  }

  /*
   * Load user information
   */
  async function load() {
    const url = "/api/users";
    console.log("Loading users");
    await axios
      .get(url, {
        headers: { Authorization: authToken.token },
      })
      .then((response) => {
        log.info("get users:", response.status);
        const { isAdmin } = response.data;
        setIsAdmin(isAdmin);
        console.log("load user:", response.data);

        //smartlook
        if (window.smartlook) {
          console.warn("smartlook:", response.data.email);
          window.smartlook("identify", response.data.email);
        } else {
          console.warn("smartlook not loaded");
        }
      });
  }

  React.useEffect(() => {
    if (authToken) {
      load();
    } else {
      setIsAdmin(false);
    }
  }, [authToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const logout = () => {
    clearAuthToken();
    setLogoutDialogOpen(false);
  };

  const openLogoutDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLogoutDialogOpen(true);
  };

  const closeLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };

  const LogoutDialog = () => {
    return (
      <div>
        <Dialog open={logoutDialogOpen} onClose={closeLogoutDialog}>
          <DialogTitle>{"Er du sikker på du vil logge ud?"}</DialogTitle>
          <DialogActions>
            <Button onClick={logout} color="primary">
              Log ud
            </Button>
            <Button onClick={closeLogoutDialog} color="primary" autoFocus>
              Fortryd
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const classes = useStyles();
  return (
    <>
      <LogoutDialog />
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar position="static">
          <Toolbar>
            <Hidden xsDown>
              <Grid container justify="center">
                <Grid
                  className={classes.container}
                  container
                  justify="space-between"
                >
                  <Grid item className={classes.brand}>
                    <Link className={classes.link} to="/jobs">
                      <Hidden xsDown>
                        <Box>
                          <Typography color="inherit" variant="h5">
                            BYGNETVÆRK
                          </Typography>
                        </Box>
                      </Hidden>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Hidden xsDown>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.menuBox}
                      >
                        <Grid item>
                          <Link className={classes.link} to="/createtask">
                            <Grid
                              container
                              wrap="nowrap"
                              className={classes.linkBox}
                            >
                              <Hidden xsDown>
                                <Grid>
                                  <Typography>OPRET UDBUD</Typography>
                                </Grid>
                              </Hidden>
                              <Hidden smUp>
                                <Grid item data-testid="createTaskMenuItem">
                                  <NoteAddIcon className={classes.icon} />
                                </Grid>
                              </Hidden>
                            </Grid>
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link className={classes.link} to="/myjobs">
                            <Grid
                              container
                              wrap="nowrap"
                              className={classes.linkBox}
                            >
                              <Hidden xsDown>
                                <Grid item>
                                  <Typography>MINE UDBUD</Typography>
                                </Grid>
                              </Hidden>
                              <Hidden smUp>
                                <Grid item data-testid="myJobsMenuItem">
                                  <AssignmentIcon className={classes.icon} />
                                </Grid>
                              </Hidden>
                            </Grid>
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link className={classes.link} to="/myapplications">
                            <Grid
                              container
                              wrap="nowrap"
                              className={classes.linkBox}
                            >
                              <Hidden xsDown>
                                <Grid item>
                                  <Typography>MINE ANMODNINGER</Typography>
                                </Grid>
                              </Hidden>
                              <Hidden smUp>
                                <Grid item data-testid="myApplicationMenuItem">
                                  <OpenInBrowserIcon className={classes.icon} />
                                </Grid>
                              </Hidden>
                            </Grid>
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link
                            className={classes.link}
                            to={
                              authToken && authToken.token
                                ? "/profile/" + jwt(authToken.token).userId
                                : "/profile"
                            }
                          >
                            <Grid
                              container
                              wrap="nowrap"
                              className={classes.linkBox}
                            >
                              <Hidden xsDown>
                                <Grid item>
                                  <Typography>MIN PROFIL</Typography>
                                </Grid>
                              </Hidden>
                              <Hidden smUp>
                                <Grid item data-testid="profileMenuItem">
                                  <AccountCircleIcon className={classes.icon} />
                                </Grid>
                              </Hidden>
                            </Grid>
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link className={classes.link} to={"/about"}>
                            <Grid
                              container
                              wrap="nowrap"
                              className={classes.linkBox}
                            >
                              <Hidden xsDown>
                                <Grid item>
                                  <Typography>OM BYGNETVÆRK</Typography>
                                </Grid>
                              </Hidden>
                              <Hidden smUp>
                                <Grid item data-testid="profileMenuItem">
                                  <InfoIcon className={classes.icon} />
                                </Grid>
                              </Hidden>
                            </Grid>
                          </Link>
                        </Grid>
                        {authToken && (
                          <Link className={classes.link}>
                            <Grid item>
                              <Grid
                                container
                                wrap="nowrap"
                                className={classes.linkBox}
                              >
                                <Hidden xsDown>
                                  <Grid item>
                                    <Box onClick={openLogoutDialog}>
                                      <Typography>LOG UD</Typography>
                                    </Box>
                                  </Grid>
                                </Hidden>
                                <Hidden smUp>
                                  <Grid item data-testid="profileMenuItem">
                                    <Box onClick={openLogoutDialog}>
                                      <ExitToAppIcon />
                                    </Box>
                                  </Grid>
                                </Hidden>
                              </Grid>
                            </Grid>
                          </Link>
                        )}
                        {isAdmin && (
                          <Grid item>
                            <Link className={classes.link} to="/admin">
                              <Grid
                                container
                                wrap="nowrap"
                                className={classes.linkBox}
                              >
                                <Hidden xsDown>
                                  <Grid item>
                                    <Typography>ADMIN</Typography>
                                  </Grid>
                                </Hidden>
                                <Hidden smUp>
                                  <Grid item data-testid="adminMenuItem">
                                    <SupervisorAccountIcon
                                      className={classes.icon}
                                    />
                                  </Grid>
                                </Hidden>
                              </Grid>
                            </Link>
                          </Grid>
                        )}
                      </Grid>
                    </Hidden>
                  </Grid>
                  <Hidden smDown>
                    <Grid item xs={1} sm={2}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.logo2}
                        onClick={handleMenu}
                        onMouseLeave={handleMouseLeave}
                        onMouseEnter={handleMouseEnter}
                        aria-owns={
                          Boolean(anchorEl) ? "mouse-over-popover" : undefined
                        }
                      ></Box>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden smUp>
              {/* TODO Logo */}
              <Box className={classes.logoNoWords}>
                <IconButton
                  onClick={() => setIsMenuOpen(true)}
                  className={classes.menu}
                >
                  <MenuIcon />
                </IconButton>
                <Avatar src={logoNoWords} />
              </Box>
            </Hidden>
            <SwipeableDrawer
              anchor="left"
              open={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
              // SlideProps={{
              //   direction: "right",
              // }}
              // with 50%
            >
              <List className={classes.menuList}>
                <Link to="/" onClick={() => setIsMenuOpen(false)}>
                  <ListItem button key={"FORSIDE"}>
                    <ListItemIcon>
                      <HomeIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={"FORSIDE"} />
                  </ListItem>
                </Link>
                <Link to="/createtask" onClick={() => setIsMenuOpen(false)}>
                  <ListItem button key={"OPRET UDBUD"}>
                    <ListItemIcon>
                      <NoteAddIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={"OPRET UDBUD"} />
                  </ListItem>
                </Link>
                <Link to="/myjobs" onClick={() => setIsMenuOpen(false)}>
                  <ListItem button key={"MINE UDBUD"}>
                    <ListItemIcon>
                      <AssignmentIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={"MINE UDBUD"} />
                  </ListItem>
                </Link>
                <Link to="/myapplications" onClick={() => setIsMenuOpen(false)}>
                  <ListItem button key={"MINE ANMODNINGER"}>
                    <ListItemIcon>
                      <OpenInBrowserIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={"MINE ANMODNINGER"} />
                  </ListItem>
                </Link>
                <Link
                  to={
                    authToken && authToken.token
                      ? "/profile/" + jwt(authToken.token).userId
                      : "/profile"
                  }
                  onClick={() => setIsMenuOpen(false)}
                >
                  <ListItem button key={"MIN PROFIL"}>
                    <ListItemIcon>
                      <AccountCircleIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={"MIN PROFIL"} />
                  </ListItem>
                </Link>
                <Link to="/about" onClick={() => setIsMenuOpen(false)}>
                  <ListItem button key={"OM BYGNETVÆRK"}>
                    <ListItemIcon>
                      <InfoIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={"OM BYGNETVÆRK"} />
                  </ListItem>
                </Link>
                {isAdmin && (
                  <Link to="/admin" onClick={() => setIsMenuOpen(false)}>
                    <ListItem button key={"ADMIN"}>
                      <ListItemIcon>
                        <SupervisorAccountIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary={"ADMIN"} />
                    </ListItem>
                  </Link>
                )}
                {authToken && (
                  <Box
                    onClick={(e) => {
                      setIsMenuOpen(false);
                      openLogoutDialog(e);
                    }}
                  >
                    <ListItem button key={"LOG UD"}>
                      <ListItemIcon>
                        <ExitToAppIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary={"LOG UD"} />
                    </ListItem>
                  </Box>
                )}
              </List>
            </SwipeableDrawer>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
}
