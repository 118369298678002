import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

// text
const styles = (theme) => ({
  root: {
    justifyContent: "center",
  },
});

function CBButtonGroup(props) {
  const { classes } = props;
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => props.onCancel()}
        >
          Afbryd
        </Button>
      </Grid>
      <Grid item>
        <Box m={1} />
      </Grid>
      <Grid item>
        <Button
          onClick={() => props.onOK()}
          variant="contained"
          color="primary"
        >
          Send
        </Button>
      </Grid>
    </Grid>
  );
}
export default withStyles(styles)(CBButtonGroup);
