import React from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import axios from "axios";
import { useAuth } from "../context/auth";
import Photo from "./Photo";
import Person from "@material-ui/icons/Person";

export default function ProfilePicture({ profile, setProfile }) {
  const { authToken } = useAuth();
  const [isPhotoDialogOpen, setPhotoDialogOpen] = React.useState(false);
  const [photoData, setPhotoData] = React.useState({});

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    gridContainer: {
      alignItems: "start",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    photo: {
      width: theme.spacing(17),
      height: theme.spacing(17),
    },
  }));

  const classes = useStyles();

  function handlePhotoDialogClose() {
    setPhotoDialogOpen(false);
  }

  async function handlePhotoDialogSave() {
    const result = await axios.put("/api/profiles/photo", photoData, {
      headers: { Authorization: authToken.token },
    });
    console.log("photoDialogSave", result);
    setProfile({ ...profile, photo: result.data.photo });
    setPhotoDialogOpen(false);
  }

  function handleAvatarClick() {
    setPhotoDialogOpen(true);
  }

  return (
    <Grid container justify="center">
      {(!profile.photo || !profile.photo.url) && (
        <Avatar
          className={clsx(classes.photo, "photo")}
          onClick={handleAvatarClick}
          title="Upload dit profilbillede"
          variant="square"
        >
          <Person className={classes.person} />
        </Avatar>
      )}
      {profile.photo && profile.photo.urlThumbnail && (
        <Avatar
          className={clsx(classes.photo, "photo")}
          onClick={handleAvatarClick}
          title="logo"
          variant="square"
          src={profile.photo.urlThumbnail}
        ></Avatar>
      )}
      <Dialog
        open={isPhotoDialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <label htmlFor="content">Rediger profilbillede</label>
          <IconButton
            onClick={handlePhotoDialogClose}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Photo
            setPhotoData={setPhotoData}
            imageOriginal={(profile.photo && profile.photo.url) || undefined}
            scale={(profile.photo && profile.photo.scale) || 1}
            position={
              (profile.photo && profile.photo.position) || { x: 0.5, y: 0.5 }
            }
          />
        </DialogContent>
        <DialogActions>
          <Box mx={2} my={1}>
            <Button onClick={handlePhotoDialogClose}>Annuller</Button>
          </Box>
          <Box mx={2} my={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePhotoDialogSave}
            >
              Gem
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
