import React, { useEffect, useState } from "react";
import axios from "../axios";
import { useAuth } from "context/auth";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import JobCard from "components/JobCard";
import { useHistory } from "react-router-dom";

const MyJobs = (props) => {
  const [myJobs, setMyJobs] = useState([]);
  const [myJobsBid, setMyJobsBid] = useState([]);
  const [myJobsCompleted, setMyJobsCompleted] = useState([]);
  const { authToken } = useAuth();
  const history = useHistory();

  useEffect(() => {
    getData();
    getDataBid();
    getDataCompleted();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    try {
      const response = await axios.get("/api/jobs/selfcreated?status=Active", {
        headers: {
          Authorization: authToken.token,
        },
      });
      setMyJobs(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  async function getDataBid() {
    try {
      const response = await axios.get("/api/jobs/selfcreated?status=bid", {
        headers: {
          Authorization: authToken.token,
        },
      });
      setMyJobsBid(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  async function getDataCompleted() {
    try {
      const response = await axios.get(
        "/api/jobs/selfcreated?status=completed",
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
      setMyJobsCompleted(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Grid container style={{ marginTop: 100 }}>
        <Typography
          className="blue"
          color="primary"
          variant="h4"
          style={{
            margin: "0 10px",
          }}
        >
          Uafsluttede udbud
        </Typography>
        <Grid container>
          {myJobs &&
            myJobs.length > 0 &&
            myJobs.map((job) => (
              <JobCard
                onClick={() => history.push(`/myjobs/${job._id}`)}
                key={job._id}
                {...job}
                enableRate={true}
              />
            ))}
          {myJobsBid &&
            myJobsBid.length > 0 &&
            myJobsBid.map((job) => (
              <JobCard
                onClick={() => history.push(`/myjobs/${job._id}`)}
                key={job._id}
                {...job}
                enableRate={true}
              />
            ))}
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 100 }}>
        <Typography
          className="blue"
          color="primary"
          variant="h4"
          style={{
            margin: "0 10px",
          }}
        >
          Afsluttede udbud
        </Typography>
        <Grid container>
          {myJobsCompleted &&
            myJobsCompleted.length > 0 &&
            myJobsCompleted.map((job) => (
              <JobCard
                onClick={() => history.push(`/myjobs/${job._id}`)}
                key={job._id}
                {...job}
                enableRate={true}
              />
            ))}
        </Grid>
      </Grid>
    </>
  );
};
export default MyJobs;
