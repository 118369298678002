import _ from "lodash";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { useAuth } from "context/auth";
import CBSuccess from "./CBSuccess";
import CBModal from "./common/CBModal";
import SignUpTwoFactor from "./SignUpTwoFactor";
import log from "loglevel";
import moment from "moment";
import utils from "../utils";
import { BUDGETS, TASK_CRITERIES } from "../constants";
import Invitation from "./common/Invitation";
import SubscribeDialog from "./SubscribeDialog";

const THE_BLUE = "#0889F8";

const styles = (theme) => ({
  root: {},
  paper: {
    margin: theme.spacing(3),
    maxWidth: 800,
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  containerB: {
    marginBottom: theme.spacing(4),
  },
  blue: {
    color: THE_BLUE,
  },
  title: {
    color: THE_BLUE,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  jobType: {
    color: THE_BLUE,
    marginBottom: theme.spacing(3),
  },
  boxItem: {
    margin: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: theme.spacing(1),
  },
  desc: {
    marginBottom: theme.spacing(4),
  },
  label: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
  input: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  submit: {
    width: "100%",
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1.5),
  },
  map: {
    //margin: theme.spacing(-3),
  },
  mapImage: {
    width: "100%",
  },
  box1: {
    marginBottom: theme.spacing(2),
  },
});

function JobDetail(props) {
  const { jobId } = useParams();
  const { authToken } = useAuth();
  const [jobDetail, setJobDetail] = useState(null);
  const [isSubmitted, setSubmitted] = useState(false);
  const [creatorProfile, setCreatorProfile] = useState({});
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const { classes } = props;

  function testApplication() {
    return Axios.post(
      `/api/jobs/${jobId}/applications`,
      {
        test: true,
      },
      {
        headers: { Authorization: authToken.token },
      },
    )
      .then((res) => {
        log.warn("application test passed");
        return true;
      })
      .catch((err) => {
        const { message, status } = utils.parseAxiosError(err);
        log.warn("application failed", status);
        if (status === 403) {
          log.warn("need modal");
          setIsSubscribing(true);
        } else if (
          status === 401 &&
          message === "Guest user has no permission to do this operation"
        ) {
          setIsSignUpOpen(true);
        } else {
          utils.handleAxiosError(err);
        }
        return false;
      });
  }

  useEffect(() => {
    const fetchUser = async () => {
      const url = "/api/users";
      console.log("Loading users");
      await Axios.get(url, {
        headers: { Authorization: authToken.token },
      }).then((response) => {
        log.info("get users:", response.status);
        console.log(response.data);
        setUser(response.data);
      });
    };
    fetchUser();
    const fetchProfile = async (userId) => {
      const requestUrl = "/api/profiles/" + userId;
      const response = await Axios.get(requestUrl, {
        headers: {
          Authorization: authToken.token,
        },
      });
      console.log(response.data);
      setCreatorProfile(response.data);
    };
    const fetchJobDetails = async () => {
      const requestUrl = "/api/jobs/" + jobId;
      Axios.get(requestUrl, {
        headers: {
          Authorization: authToken.token,
        },
      })
        .then((response) => {
          setJobDetail(response.data);
          fetchProfile(response.data.createdBy);
        })
        .catch((e) => {
          log.error("Failed to fetch job details");
          utils.handleAxiosError(e);
        });
    };
    fetchJobDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const priorities = (jobDetail && jobDetail.prioritiesSelected) || "";

  const formik = useFormik({
    initialValues: {
      message: "",
      resolve: "",
      priority: "",
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting }) => {
      log.warn("deal with submit");
      setSubmitting(true);
      console.log("values");
      console.log(values);
      const result = await testApplication();
      if (!result) {
        return;
      }
      await Axios.post(
        `/api/jobs/${jobId}/applications`,
        {
          ...values,
          applicantId: jobId,
        },
        {
          headers: { Authorization: authToken.token },
        },
      )
        .then((res) => {
          log.warn("application created");
          setSubmitting(false);
          setSubmitted(true);
        })
        .catch((err) => {
          log.warn("application failed");
          setSubmitting(false);
          setSubmitted(false);
          const { message, status } = utils.parseAxiosError(err);
          if (
            status === 401 &&
            message === "Guest user has no permission to do this operation"
          ) {
            log.warn("need modal");
            setIsSignUpOpen(true);
          } else {
            utils.handleAxiosError(err);
          }
        });
    },
  });
  const {
    // values,
    errors,
    touched,
    handleChange: onChange,
    handleBlur: onBlur,
    handleSubmit,
    // handleReset,
  } = formik;

  function handleSignUpSuccess() {
    setIsSignUpOpen(false);
  }

  if (!jobDetail) {
    return null;
  }

  function handleSuccess() {
    message.success("Succes med abonnement", 5);
    setIsSubscribing(false);
  }

  return (
    <>
      <Grid container className={classes.root} justify="center">
        <Paper elevation={0} className={classes.paper}>
          <Grid className={classes.container} container direction="column">
            <Grid item>
              <Typography className={classes.title} variant="h5">
                {props.title}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              className={classes.containerB}
            >
              <Grid item>
                <Grid container>
                  <Grid item style={{ width: "100%" }}>
                    <Grid container direction="row" justify="space-between">
                      <Grid item xs={12} sm={8}>
                        <Typography className={classes.jobType} variant="h4">
                          {jobDetail.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Invitation
                          user={user}
                          setIsSignUpOpen={setIsSignUpOpen}
                          authToken={authToken}
                          jobId={jobId}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item className={classes.boxItem}>
                      <Typography variant="subtitle2">Fast budget</Typography>
                      <Typography variant="body2" data-testid="budget">
                        {BUDGETS[jobDetail.budget].text}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.boxItem} xs={2}>
                      <Typography variant="subtitle2">Arbejdssted</Typography>
                      <Typography variant="body2">
                        {jobDetail.location}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.boxItem} xs={3}>
                      <Typography variant="subtitle2">
                        Frist for anmodninger
                      </Typography>
                      <Typography variant="body2">
                        {moment(jobDetail.deadline).format("YYYY-MM-DD HH:mm")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      className={clsx(classes.blue)}
                    >
                      Ordregiver
                    </Typography>
                  </Grid>
                  <Grid container wrap="nowrap">
                    <Grid item>
                      <Avatar
                        className={classes.avatar}
                        alt="logo"
                        src={_.get(
                          creatorProfile,
                          "photo.urlThumbnail",
                          "http://",
                        )}
                      />
                    </Grid>
                    {creatorProfile.companyName === "fakeCompanyName" ? null : (
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          className={clsx(classes.blue)}
                        >
                          {creatorProfile.companyName}
                        </Typography>
                        <Typography variant="body2">
                          {creatorProfile.streetAddress}
                        </Typography>
                        <Typography variant="body2">
                          {creatorProfile.postalCode} {creatorProfile.cityName}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.desc}>
              <Typography variant="body2">{jobDetail.description}</Typography>
            </Grid>
            {!isSubmitted && (
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  {jobDetail && jobDetail.fileName && (
                    <Button
                      color="primary"
                      className={classes.submit}
                      variant="contained"
                      onClick={() =>
                        window.open(`/jobs/${jobDetail._id}/viewer`, "_blank")
                      }
                    >
                      Se udbudsbeskrivelse
                    </Button>
                  )}
                  {jobDetail && !jobDetail.fileName && (
                    <Button
                      color="primary"
                      className={classes.submit}
                      variant="contained"
                      onClick={() =>
                        window.open(`/jobs/${jobDetail._id}/viewer`, "_blank")
                      }
                      disabled
                    >
                      Ordregiver har ikke uploadet en udbudsbeskrivelse
                    </Button>
                  )}
                </Grid>
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container direction="column" justify="center">
                    <Grid item className={classes.box1}>
                      <Typography
                        className={clsx(classes.label, classes.blue)}
                        component="label"
                        variant="subtitle1"
                        htmlFor="message"
                      >
                        Skriv kort til ordregiver og fortæl hvorfor du er
                        interesseret i at blive udvalgt (prækvalificeret) til at
                        afgive tilbud på udbuddet f.eks. om din erfaring m.v.
                      </Typography>
                      <TextField
                        id="message"
                        onChange={onChange}
                        onBlur={onBlur}
                        helperText={touched.message ? errors.message : ""}
                        error={touched.message && Boolean(errors.message)}
                        variant="outlined"
                        multiline
                        rows={2}
                        className={classes.input}
                      />
                    </Grid>
                    <Grid item className={classes.box1}>
                      <Typography
                        className={clsx(classes.label, classes.blue)}
                        component="label"
                        variant="subtitle1"
                        htmlFor="resolve"
                      >
                        Skriv kort til ordregiver og fortæl kort, hvordan du
                        kunne forestille dig, at løse opgaven - f.eks. med
                        hvornår, hvordan og med hvor mange osv.
                      </Typography>
                      <TextField
                        id="resolve"
                        onChange={onChange}
                        onBlur={onBlur}
                        helperText={touched.resolve ? errors.resolve : ""}
                        error={touched.resolve && Boolean(errors.resolve)}
                        variant="outlined"
                        multiline
                        rows={2}
                        className={classes.input}
                      />
                    </Grid>
                    <Grid item className={classes.box1}>
                      <Typography
                        className={clsx(classes.label, classes.blue)}
                        component="label"
                        variant="subtitle1"
                        htmlFor="priority"
                      >
                        Skriv kort til ordregiver og fortæl kort hvordan du vil
                        kunne opfylde kriterierne <br />
                        <strong>
                          {priorities
                            .map((p) => TASK_CRITERIES.findNameByValue(p))
                            .join(", ")}{" "}
                        </strong>
                      </Typography>
                      <TextField
                        id="priority"
                        onChange={onChange}
                        onBlur={onBlur}
                        helperText={touched.priority ? errors.priority : ""}
                        error={touched.priority && Boolean(errors.priority)}
                        variant="outlined"
                        multiline
                        rows={2}
                        className={classes.input}
                      />
                    </Grid>

                    <Grid item>
                      <Button
                        color="primary"
                        className={classes.submit}
                        variant="contained"
                        type="submit"
                      >
                        SEND DIN ANMODNING OM AT BLIVE PRÆKVALIFICERET TIL AT
                        AFGIVE TILBUD
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
            {isSubmitted && (
              <Grid item container justify="center" alignItems="center">
                <CBSuccess>ANMODNINGER ER NU SENDT</CBSuccess>
              </Grid>
            )}
            {/* <Grid item>
          <Box height={40} />
          <Typography
            component="label"
            htmlFor="map"
            className={classes.label}
            variant="h6"
            align="center"
          >
            Arbejdssted
          </Typography>
        </Grid> */}
          </Grid>
          {/* <Grid item className={classes.map} id="map" xs={12}>
        <img className={classes.mapImage} src={require("../temp/map.png")} />
      </Grid> */}
          {/* <Grid container className={classes.container} direction="column">
        <Grid item>
          <Typography
            component="label"
            htmlFor="map"
            className={classes.label}
            variant="h6"
            align="center"
          >
            Lignende udbud
          </Typography>
        </Grid>
      </Grid> */}
          {/* <Grid container
        direction="row"
        justify="center"
      >
        {Array.from(new Array(6)).map((e,i) => 
        <JobCard key={i} {...jobCardData} />
        )}
      </Grid> */}
          <Box height={40} />
        </Paper>
      </Grid>
      {isSignUpOpen && (
        <Box>
          <CBModal>
            <SignUpTwoFactor mode="employee" onSuccess={handleSignUpSuccess} />
          </CBModal>
        </Box>
      )}
      {isSubscribing && (
        <Box>
          <CBModal>
            <Paper
              style={{
                background: "white",
                maxWidth: "500px",
                overflow: "scroll",
              }}
            >
              <SubscribeDialog onSuccess={handleSuccess} />
            </Paper>
          </CBModal>
        </Box>
      )}
    </>
  );
}

const validationSchema = (priorities = []) => {
  console.log("mem");
  console.log(priorities);
  return Yup.object().shape({
    message: Yup.string()
      .required("Indtast noget indhold.")
      .max(500, "Indholdet er for langt."),
    resolve: Yup.string()
      .required("Indtast noget indhold.")
      .max(500, "Indholdet er for langt."),
    priority: Yup.string()
      .required("Indtast noget indhold.")
      .max(500, "Indholdet er for langt."),
  });
};

export default withStyles(styles)(JobDetail);
