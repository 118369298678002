import CreateTask from "./CreateTask";
import log from "loglevel";
import axios from "axios";
import { useAuth } from "../context/auth";

import React from "react";

export default function EditTask(props) {
  const { authToken } = useAuth();
  const [isLoading, setIsLoading] = React.useState(true);
  const [task, setTask] = React.useState({});
  async function loadTask() {
    // get taksId from url
    const taskId = props.match.params.taskId;
    if (taskId) {
    } else {
      throw new Error("TaskId is not defined");
    }
    if (taskId) {
      // get task info
      const res = await axios.request({
        url: `/api/jobs/${taskId}`,
        headers: {
          Authorization: authToken.token,
        },
      });
      log.warn("res:", res);
      setTask(res.data);
      setIsLoading(false);
    } else {
      log.warn("taskId is null");
      setIsLoading(false);
    }
  }
  React.useEffect(() => {
    loadTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return <CreateTask task={task} />;
}
