import {
  Menu,
  MenuItem,
  Checkbox,
  Button,
  Box,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { JOB_POSITIONS } from "../constants";
import * as Yup from "yup";
import React from "react";
import log from "loglevel";
import axios from "axios";
import { useAuth } from "../context/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { message } from "antd";
import { parseAxiosError } from "../utils";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = (theme) => ({
  phoneBox: {
    "& .iti--allow-dropdown": {
      width: "100%",
      borderRadius: "4px",
      color: "rgba(0, 0, 0, 0.87)",
      cursor: "text",
      display: "inline-flex",
      position: "relative",
      fontSize: "1rem",
      boxSizing: "border-box",
      alignItems: "center",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "400",
      lineHeight: "1.1876em",
      letterSpacing: "0.00938em",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: "1px",
      borderStyle: "solid",
    },
    "& input": {
      width: "100%",
      boxShadow: "none",
      padding: "18.5px 14px",
      marginLeft: 32,
    },
  },
  box1: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
    "&>div": {
      maxWidth: "468px",
      margin: "18px",
    },
    "&>div>:nth-child(2)": {
      marginTop: 4,
    },
    "&>div>:nth-child(3)": {
      marginTop: 10,
    },
  },
  verifyCode: {
    width: "100%",
  },
});

const disablePositionSelector = true;

const validationSchema = Yup.object().shape({
  //firstname not allow space
  firstName: Yup.string()
    .required("Fornavn er påkrævet")
    .matches(
      /^[a-zA-ZæøåÆØÅ]+$/,
      "Fornavn må ikke indeholde tal eller specialtegn",
    ),
  phone: Yup.string()
    .test("required", "Telefon nr. mangler", (val, testContext) => {
      if (!val) {
        return false;
      } else {
        return true;
      }
    })
    .test(
      "format",
      "Indtast venligst et gyldigt telefonnummer",
      (val, testContext) => {
        if (!val) return true;
        if (val.match(/^\+?[0-9]{8,13}$/)) {
          return true;
        } else {
          return false;
        }
      },
    ),
});

function SignUpTwoFactor(props) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isVerifying, setIsVerifying] = React.useState(false);
  const [verifyCode, setVerifyCode] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { classes } = props;
  const isMobile = useMediaQuery("(max-width: 600px)");

  const user = useAuth();
  log.warn("user: ", user);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      cvr: "",
      phone: "",
      acceptTerms: false,
      email: "",
      accountType: "company",
      jobSubscriptions: JOB_POSITIONS.map((e) => e.id),
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setErrorMessage("");
      setIsSubmitting(true);
      log.warn("do submit");
      await axios({
        method: "post",
        url: "/api/sms/verification",
        data: {
          phone: values.phone,
        },
      })
        .then((res) => {
          log.warn("verified", res);
          setIsVerifying(true);
        })
        .catch((error) => {
          log.error("error:", error);
          if (error.response.status === 422) {
            setErrorMessage("Noget gik galt");
          } else {
            setErrorMessage(parseAxiosError(error).message);
          }
        });
      setIsSubmitting(false);
      return;
    },
  });

  const {
    touched,
    errors,
    handleChange: onChange,
    handleBlur: onBlur,
    handleSubmit,
    values,
  } = formik;

  function onVerifyCodeChange(e) {
    setVerifyCode(e.target.value);
  }

  function onVerify() {
    setErrorMessage("");
    axios({
      method: "put",
      url: "/api/sms/verification",
      data: {
        phone: values.phone,
        code: verifyCode,
      },
    })
      .then((res) => {
        log.warn("verified", res);
        onRealSubmit();
      })
      .catch((error) => {
        log.error("error:", error);
        if (error.response.status === 422) {
          setErrorMessage("ugyldig sms-kode");
        } else {
          setErrorMessage(parseAxiosError(error).message);
        }
      });
  }

  async function onRealSubmit() {
    setIsSubmitting(true);
    let result;
    try {
      // axios pathch to /api/user/self

      result = await axios({
        method: "patch",
        url: "/api/users/self",
        data: values,
        headers: {
          Authorization: user.authToken.token,
        },
      });
      if (result.status === 200) {
        message.success("lykkedes!", 5);
        props.onSuccess && props.onSuccess();
      }
      console.log(result);
    } catch (error) {
      log.error("error:", error);
      setErrorMessage(
        "Der opstod en fejl ved oprettelse. Prøv igen senere, eller kontakt Bygnetværk.",
      );
    }
    setIsSubmitting(false);
  }

  async function handleSendAgain() {
    const res = await axios({
      method: "post",
      url: "/api/sms/verification",
      data: {
        phone: values.phone,
      },
    });
    if (res.status !== 200) {
      log.error("res:", res);
      setErrorMessage("Noget gik galt");
    }
  }

  function handleJobSubscriptionsClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleJobSubscriptionsClose() {
    setAnchorEl(null);
  }

  function handleJobSubscriptionClick(id) {
    log.info("js click:", id);
    const oldValue = values.jobSubscriptions;
    log.info("old value:", oldValue);
    let value;
    if (oldValue.includes(id)) {
      log.info("remove js");
      value = oldValue.filter((item) => item !== id);
    } else {
      log.info("add js");
      value = [...oldValue, id].sort();
    }
    log.info("new value:", value);
    const e = {
      target: {
        id: "jobSubscriptions",
        value,
      },
    };
    onChange(e);
  }

  return (
    <>
      {!isVerifying && (
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Box
            sx={{
              minWidth: 300,
              display: "flex",
              flexDirection: "column",
              "& > .MuiFormControl-root": {
                marginTop: 20,
              },
              "& > button": {
                marginTop: 20,
              },
              "& > .MuiTypography-root": {
                marginTop: 20,
              },
            }}
          >
            <Typography color="primary" variant="h5" align="center">
              2-faktor godkend din profil
            </Typography>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              helperText={touched.firstName ? errors.firstName : ""}
              error={touched.firstName && Boolean(errors.firstName)}
              value={values.firstName}
              required
              fullWidth
              id="firstName"
              label="Fornavn"
            />
            <TextField
              autoComplete="phone"
              name="phone"
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              helperText={touched.phone ? errors.phone : ""}
              error={touched.phone && Boolean(errors.phone)}
              value={values.phone}
              required
              fullWidth
              id="phone"
              label="Mobil nr."
            />
            {!disablePositionSelector && props.mode === "employee" && (
              <>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="jobSubscriptions"
                  label="Job Subscriptions"
                  type="text"
                  id="jobSubscriptions"
                  autoComplete="new-password"
                  onBlur={onBlur}
                  onChange={onChange}
                  helperText={
                    touched.jobSubscriptions ? errors.jobSubscriptions : ""
                  }
                  error={
                    touched.jobSubscriptions && Boolean(errors.jobSubscriptions)
                  }
                  value={
                    values.jobSubscriptions &&
                    values.jobSubscriptions
                      .map((e) => JOB_POSITIONS[e].name)
                      .join(", ")
                  }
                  onClick={handleJobSubscriptionsClick}
                  disabled
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleJobSubscriptionsClose}
                >
                  <Button
                    style={{ display: "none" }}
                    id="closeMenu"
                    onClick={handleJobSubscriptionsClose}
                  >
                    X
                  </Button>
                  {JOB_POSITIONS.map((e) => (
                    <MenuItem onClick={() => handleJobSubscriptionClick(e.id)}>
                      <Checkbox
                        checked={values.jobSubscriptions.includes(e.id)}
                      />
                      {e.name}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
            <Typography variant="subtitle2" color="error">
              {errorMessage}
            </Typography>
            <Button type="submit" fullWidth variant="contained" color="primary">
              {isSubmitting && <CircularProgress color="white" />}
              {!isSubmitting && "BEKRÆFT"}
            </Button>
          </Box>
        </form>
      )}

      {isVerifying && (
        <Box className={classes.box1}>
          <Box>
            <Typography variant="body1">
              Vi har sendt en verifikationskode til '{values.phone}'
            </Typography>
            <Box>
              {/*mobile device*/}
              {isMobile && (
                <>
                  <SendAgainButton onClick2={handleSendAgain} />
                  <TextField
                    label=""
                    variant="outlined"
                    placeholder="Verify SMS code"
                    onChange={onVerifyCodeChange}
                    value={verifyCode}
                    name="verifyCode"
                    className={classes.verifyCode}
                  />
                </>
              )}
              {!isMobile && (
                <TextField
                  label=""
                  variant="outlined"
                  placeholder="Verify SMS code"
                  onChange={onVerifyCodeChange}
                  value={verifyCode}
                  name="verifyCode"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SendAgainButton onClick2={handleSendAgain} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Box>
            <Typography variant="subtitle2" color="error">
              {errorMessage}
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.verify}
              onClick={onVerify}
            >
              {isSubmitting && <CircularProgress color="white" />}
              {!isSubmitting && "Verify"}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

const SendAgainButton = withStyles(styles)(function (props) {
  const COUNT = 60;
  const { onClick2 /*classes*/ } = props;
  const [isClickable, setIsClickable] = React.useState(false);
  const [countDown, setCountDown] = React.useState(COUNT);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDown - 1);
      if (countDown === 0) {
        setIsClickable(true);
        setCountDown(COUNT);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  function handleClick() {
    setIsClickable(false);
    setCountDown(COUNT);
    onClick2();
  }

  return (
    <>
      {isClickable && (
        <Button onClick={handleClick} variant="contained" color="primary">
          SEND SMS KODEN IGEN
        </Button>
      )}
      {!isClickable && (
        <Button
          style={{ textTransform: "none", width: "100%" }}
          caption="false"
          disabled={true}
          variant="outlined"
          color="primary"
        >
          {countDown}sekunder for at kunne sende SMS igen
        </Button>
      )}
    </>
  );
});

export default withStyles(styles)(SignUpTwoFactor);
