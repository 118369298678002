/*
 * To display my job, a single one
 */
import moment from "moment";
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { useAuth } from "context/auth";
import { useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AttachFile from "@material-ui/icons/AttachFile";
import ReviewApplications from "components/ReviewApplications";
import { JOB_POSITIONS, BUDGETS, TASK_CRITERIES } from "../constants";
import { useHistory } from "react-router-dom";
import log from "loglevel";
import { message } from "antd";
import { Divider } from "@material-ui/core";
import Invitation from "./common/Invitation";

const THE_BLUE = "#0889F8";

const style = (theme) => ({
  root: {},
  blue: {
    color: THE_BLUE,
  },
  paper: {
    width: "90%",
    margin: theme.spacing(3),
    padding: theme.spacing(4),
    backgroundColor: "transparent",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      width: "100%",
      padding: theme.spacing(2),
    },
  },
  title: {
    color: THE_BLUE,
    marginBottom: theme.spacing(3),
  },
  title2: {
    color: THE_BLUE,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  tab: {
    marginBottom: theme.spacing(3),
  },
  jobType: {
    color: THE_BLUE,
    marginBottom: theme.spacing(2),
  },
  box1: {
    marginBottom: theme.spacing(2),
  },
  box2: {
    marginRight: theme.spacing(2),
  },
  application: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(0.5),
    fontSize: 32,
    width: 60,
    height: 60,
  },
  profile: {},
  thumbBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  thumb: {
    fontSize: 70,
    color: THE_BLUE,
  },
  boxLeft: {
    height: "100%",
  },
  desc: {
    marginBottom: theme.spacing(5),
  },
  priorityBox: {
    marginBottom: theme.spacing(5),
  },
  priority: {
    marginRight: theme.spacing(1),
  },
  attachFile: {
    fontSize: 20,
  },
  thumbsUpDown: {
    marginLeft: theme.spacing(1),
  },
  tips1: {
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  startBidRoundButtonBox: {
    justifyContent: "center",
    "& button": {
      minWidth: 260,
      margin: 10,
    },
  },
});

function MyJobDetail(props) {
  const history = useHistory();
  const { classes } = props;
  const { jobId } = useParams();
  const { authToken } = useAuth();
  const [jobDetail, setJobDetail] = useState(null);
  const [jobApplications, setJobApplications] = useState([]);
  const [show, setShow] = useState(false);
  const [startBidRoundButtonDisabled, setStartBidRoundButtonDisabled] =
    React.useState(false);
  const [isStartBidRoundConfirmOpen, setIsStartBidRoundConfirmOpen] =
    React.useState(false);

  const fetchJobDetails = async () => {
    const requestUrl = "/api/jobs/" + jobId;
    const response = await axios.get(requestUrl, {
      headers: {
        Authorization: authToken.token,
      },
    });
    log.log("jobDetail", response.data);
    setJobDetail(response.data);
    //bid already started
    if (response.data.bidStartedAt) {
      setStartBidRoundButtonDisabled(true);
    }
    {
      const requestUrl = `/api/jobs/${jobId}/applications`;
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: authToken.token,
        },
      });
      setJobApplications(response.data);
    }
  };

  useEffect(() => {
    fetchJobDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (jobDetail?.bidStartedAt) {
      return;
    }
    setStartBidRoundButtonDisabled(false);
    if (
      jobApplications.filter(
        (application) => application.acceptedForPrequalification,
      ).length === 0
    ) {
      console.log("jobApplications is 0");
      setStartBidRoundButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobApplications]);

  if (!jobDetail) {
    return null;
  }

  async function handlePreQualification(jobApplication) {
    jobApplication.acceptedForPrequalification =
      !jobApplication.acceptedForPrequalification;

    const response = await axios({
      method: "patch",
      url: `/api/jobs/${jobId}/applications/${jobApplication._id}`,
      data: {
        acceptedForPrequalification: jobApplication.acceptedForPrequalification,
      },
      headers: {
        Authorization: authToken.token,
      },
    });
    //TODO maybe should change response code to 201? Because this is a
    //request cuz server status change.
    if (response.status === 200) {
      //refresh UI
      setJobApplications(
        jobApplications.map((application) => {
          if (application._id === jobApplication._id) {
            return jobApplication;
          } else {
            return application;
          }
        }),
      );
    }
  }

  async function handleViewedApplication(applicationIds) {
    const url = `/api/applications/${applicationIds.join(",")}/view`;
    const response = await axios.patch(
      url,
      {},
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );
    //TODO maybe should change response code to 201? Because this is a
    //request cuz server status change.
    if (response && response.status === 200) {
      //refresh UI
      setJobApplications(
        jobApplications.map((jobApplication) => {
          return { ...jobApplication, viewedApplication: true };
        }),
      );
    }
  }

  async function handleTaskDelete() {
    const url = `/api/jobs/${jobId}/status`;
    const response = await axios.patch(
      url,
      { status: "Deleted" },
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );
    //TODO maybe should change response code to 201? Because this is a
    //request cuz server status change.
    if (response && response.status === 200) {
      // redirect to myjobs page
      history.push("/myjobs");
    }
  }

  const handleDialogShow = () => {
    setShow(true);
  };

  const handleDialogClose = () => {
    setShow(false);
  };

  function handleStartBidRoundConfirm() {
    setIsStartBidRoundConfirmOpen(true);
  }

  function handleStartBidRoundConfirmClose() {
    setIsStartBidRoundConfirmOpen(false);
  }

  async function handleStartBidRound() {
    setIsStartBidRoundConfirmOpen(false);
    setStartBidRoundButtonDisabled(true);
    await axios({
      method: "put",
      url: `/api/jobs/${jobId}/startBidRound`,
      headers: {
        Authorization: authToken.token,
      },
    })
      .then((res) => {
        log.info("res:", res);
        message.success(
          "Bud runde er nu startet! Vi har sendt en email for at informere ansøgere. Du vil modtage bud fra ansøgere snarest.",
          5,
        );
        setStartBidRoundButtonDisabled(true);

        fetchJobDetails();
      })
      .catch((e) => {
        log.error("e:", e);
      });
  }

  async function handleDownload(id) {
    await axios({
      method: "post",
      url: `/api/applications/${id}/download_offer`,
      headers: {
        Authorization: authToken.token,
      },
    })
      .then(() => {
        log.info("record download is done, refresh");
        fetchJobDetails();
      })
      .catch((e) => {
        log.error("error:", e);
      });
  }

  return (
    <Grid className={classes.root} container justify="center">
      <Paper className={classes.paper} elevation={0}>
        <Typography variant="h4" className={classes.title}>
          {jobDetail.title}
        </Typography>
        <Divider />
        <Grid container direction="row">
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" className={classes.title2}>
              VIS UDBUD
            </Typography>
            <Grid item className="title">
              <Button
                className={classes.buttonSpacing}
                variant="contained"
                color="primary"
                size="large"
                disabled={!jobDetail.isEditable}
                onClick={() => history.push(`/tasks/${jobId}`)}
              >
                REDIGER UDBUD
              </Button>
              <Button
                className={classes.tab}
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleDialogShow}
                disabled={jobDetail.status === "Deleted" ? true : false}
              >
                SLET UDBUD
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Invitation
              authToken={authToken}
              skipUserChecking={true}
              jobId={jobId}
            />
          </Grid>
        </Grid>

        <>
          <Typography variant="h5" className={classes.jobType}>
            {
              JOB_POSITIONS.filter(
                (position) => parseInt(jobDetail.position) === position.id,
              )[0].name
            }
          </Typography>
          <Grid container direction="row" className={classes.box1}>
            <Grid item className={classes.box2}>
              <Typography variant="subtitle2">Fast Budget</Typography>
              <Typography variant="body2">
                {BUDGETS[jobDetail.budget].text}
              </Typography>
            </Grid>
            <Grid item className={classes.box2}>
              <Typography variant="subtitle2">Arbejdssted</Typography>
              <Typography variant="body2">{jobDetail.location}</Typography>
            </Grid>
            <Grid item className={classes.box2}>
              <Typography variant="subtitle2">Frist for anmodninger</Typography>
              <Typography variant="body2">
                {moment(jobDetail.deadline).format("YYYY-MM-DD HH:mm")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7} className={classes.desc}>
            <Typography>{jobDetail.description}</Typography>
          </Grid>
          <Typography variant="h5" className={classes.jobType}>
            Udvælgelseskriterier
          </Typography>
          <Grid className={classes.priorityBox}>
            {jobDetail.prioritiesSelected.map((p) => (
              <Chip
                color="primary"
                className={classes.priority}
                key={p}
                label={TASK_CRITERIES.findNameByValue(p)}
              />
            ))}
          </Grid>
          {jobDetail && jobDetail.fileName && (
            <>
              <Typography variant="h5" className={classes.jobType}>
                Vedhæftede filer
              </Typography>
              <Grid container>
                <Grid>
                  <AttachFile className={classes.attachFile} />
                </Grid>
                <Grid>
                  <Typography>{jobDetail.fileName}</Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
        <Box height={50} />
        <Divider />
        <Typography variant="h5" className={classes.title2}>
          GENNEMSE ANMODNINGER
        </Typography>
        {/* TODO Refactor this to a separate component */}
        <ReviewApplications
          jobApplications={jobApplications}
          jobId={jobId}
          handlePreQualification={handlePreQualification}
          handleViewedApplication={handleViewedApplication}
          handleDownload={handleDownload}
          bidStartedAt={jobDetail?.bidStartedAt}
        />
        <Grid container className={classes.startBidRoundButtonBox}>
          <Button
            variant="contained"
            disabled={startBidRoundButtonDisabled}
            onClick={handleStartBidRoundConfirm}
            color="primary"
          >
            Start Udvælgelsesfase
          </Button>
          <Dialog
            open={isStartBidRoundConfirmOpen}
            onClose={handleStartBidRoundConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Du vil ikke kunne vælge flere kandidater efter du starter
                udvælgelsesfase, ønsker du at følge tilbudsloven bør du sikre
                dig, at antallet af kandidater passer med gældende kvote.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleStartBidRoundConfirmClose}
                color="primary"
                variant="contained"
              >
                Jeg venter
              </Button>
              <Button
                onClick={handleStartBidRound}
                color="primary"
                autoFocus
                variant="contained"
                id="confirm-button"
              >
                Start udvælgelsesfase
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Dialog
          open={show}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Slet bekræftelse"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du sikker på, at du vil slette denne udbud?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleDialogClose}
              color="primary"
            >
              Nej
            </Button>
            <Button
              variant="contained"
              onClick={handleTaskDelete}
              color="secondary"
              autoFocus
            >
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Grid>
  );
}

export default withStyles(style)(MyJobDetail);
