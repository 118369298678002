import React from "react";
import axios from "../axios";
import Grid from "@material-ui/core/Grid";
import JobCard from "./JobCard";

export default function CompletedJobs({ userId, authToken }) {
  const [completedJobs, setCompletedJobs] = React.useState([]);

  async function loadCompletedJobs(userId) {
    if (!userId) {
      return;
    }

    const res = await axios.get(
      `/api/jobs/list?status=completed&applicantId=${userId}&winnerConfirmedWasActualWinner=true`,
      {
        headers: { Authorization: authToken.token },
      },
    );
    setCompletedJobs(res.data.jobs);
  }

  React.useEffect(() => {
    loadCompletedJobs(userId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid item>
      <Grid container>
        {completedJobs.map((completedJob) => (
          <JobCard
            key={completedJob._id}
            {...completedJob}
            enableRate={false}
            disableClick
          />
        ))}
      </Grid>
    </Grid>
  );
}
