/*
 * A temporarily component for test payment
 */
import React from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import log from "loglevel";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { message } from "antd";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "'Helvetica Neue', Helvetica, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
}

function PaymentSandbox() {
  const [secret, setSecret] = React.useState(undefined);

  async function load() {
    //get all applications for current user
    await axios
      .request({
        url: "/test/payment/init",
        method: "GET",
      })
      .then((res) => {
        log.info("res:", res.data);
        setSecret(res.data.paymentSecret);
      });
  }

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: "Dadior Chen",
          email: "dadiorchen@outlook.com",
          phone: "12345678",
        },
      },
      setup_future_usage: "off_session",
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        log.info("payment success!");
        axios
          .request({
            url: "/test/payment/confirm",
            method: "post",
            data: {
              secret,
            },
          })
          .then((res) => {
            log.info("res:", res);
          });
      } else {
        log.warn("payment result:", result.paymentIntent);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardSection />
        <Button type="submit" variant="contained">
          Pay
        </Button>
      </form>
      <SecondPay />
    </>
  );
}

function SecondPay() {
  const [customerId, setCustomerId] = React.useState(undefined);
  function refresh() {
    axios
      .request({
        url: "/test/payment/refresh",
        method: "GET",
      })
      .then((res) => {
        log.warn("res:", res);
      });
  }

  function handleRefresh() {
    refresh();
  }

  function handlePayAgain() {
    axios
      .request({
        url: "/test/payment/pay-again",
        method: "POST",
        data: {
          customerId,
        },
      })
      .then((res) => {
        log.warn("res:", res);
      });
  }

  function handleChange(e) {
    setCustomerId(e.target.value);
  }

  return (
    <Box>
      <Box>Try to pay later:</Box>
      <Button onClick={handleRefresh} variant="contained">
        Refresh
      </Button>
      <input
        onChange={handleChange}
        value={customerId}
        placeholder="stripe customer id"
      />
      <Button onClick={handlePayAgain} variant="contained">
        Pay again
      </Button>
      <Box height={10} />
      <Button
        onClick={() => {
          message.success("Paid successfully!");
        }}
      >
        Test alert box
      </Button>
    </Box>
  );
}

export default PaymentSandbox;
