import _ from "lodash";
import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import PanTool from "@material-ui/icons/PanTool";
import { withStyles } from "@material-ui/core/styles";
import WatchLater from "@material-ui/icons/WatchLater";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/da";
import { JOB_POSITIONS, BUDGETS, TASK_CRITERIES } from "../constants";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import log from "loglevel";
import RateModal from "./RateModal";
import { Modal } from "antd";
import Rating from "@material-ui/lab/Rating";
import HelpIcon from "@material-ui/icons/Help";
import Check from "@material-ui/icons/Check";
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1.5),
    maxWidth: 440,
    minWidth: 388,
    // mobile
    [theme.breakpoints.down("xs")]: {
      margin: "12px 0",
    },
  },
  location: {
    marginLeft: theme.spacing(1),
  },
  topDiv: {
    padding: theme.spacing(2),
    height: "100%",
  },
  bottomDiv: {
    width: "100%",
  },
  money: {
    fontWeight: 600,
  },
  currency: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(0.5),
  },
  avatar: {
    width: 80,
    height: 80,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      //      width: "100%",
      //      height: "100%",
    },
  },
  desc: {
    marginTop: theme.spacing(2),
  },
  item1: {
    marginBottom: theme.spacing(1.5),
  },
  item2: {
    minHeight: 70,
  },
  priority: {
    margin: theme.spacing(0.5),
  },
  buttonRate: {
    margin: theme.spacing(0.5),
  },
  titleBox: {
    display: "flex",
  },
  check: {
    fill: "gray",
  },
  tooltip: {
    //background: "transparent",
    //color: "rgba(0, 0, 0, 0.87)",
    // fontSize: theme.typography.pxToRem(12),
    // border: '1px solid #dadde9',
    minWidth: 450,
  },
  table: {},
});

function JobCard(props) {
  moment.locale("da");
  const history = useHistory();
  const [openRateDialog, setOpenRateDialog] = React.useState(false);
  const { classes, enableRate, disableClick } = props;

  console.log(props);

  function handleClick() {
    //Prefer the onClick by creator of this component
    if (props.onClick) {
      props.onClick();
    } else {
      history.push("/jobs/" + props._id);
    }
  }

  function handleRateClick(e) {
    log.warn("handleRateClick");
    e.stopPropagation();
    e.preventDefault();
    setOpenRateDialog(true);
  }

  function handleFinished() {
    setOpenRateDialog(false);
    Modal.info({
      title:
        "Tak for din rating! Din udbud vil nu blive flyttet til afsluttede udbud.",
      okText: "okay",
      onOk() {
        log.warn("click ok to continue");
        /* simply reload the page to check the updated list*/
        window.location.reload();
      },
    });
  }

  function handleChooseWinnerClick(e) {
    e.stopPropagation();
    e.preventDefault();
    log.warn("to jump to choose winner modal");
    history.push("/chooseWinnerModal");
  }

  return (
    <>
      <Card
        className={classes.root}
        elevation={4}
        onClick={disableClick ? null : handleClick}
      >
        <Grid
          direction="column"
          container
          justify="space-between"
          className={classes.topDiv}
        >
          <Grid item>
            <Grid>
              <Grid item className={classes.item1}>
                <Grid container justify="space-between">
                  <Grid item sm={9} xs={9}>
                    <Grid container direction="column">
                      <Grid item>
                        <Box className={classes.titleBox}>
                          <Typography variant="h6">{props.title}</Typography>
                          {props.warns && props.warns.length > 0 && (
                            <Tooltip
                              classes={{ tooltip: classes.tooltip }}
                              title={
                                <Paper>
                                  <Box>
                                    <TableContainer component={Paper}>
                                      <Table
                                        className={classes.table}
                                        aria-label="simple table"
                                      >
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>
                                              udbud offentliggjort
                                            </TableCell>
                                            <TableCell align="right">
                                              <strong>
                                                {
                                                  props.createdBy
                                                    .totalJobPublished
                                                }
                                              </strong>{" "}
                                              gange
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>
                                              udbud bekræftet afsluttet
                                            </TableCell>
                                            <TableCell align="right">
                                              <strong>
                                                {
                                                  props.createdBy
                                                    .totalJobFinishedAndConfirmed
                                                }
                                              </strong>{" "}
                                              gange
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>
                                              Samme leverandør valgt
                                            </TableCell>
                                            <TableCell align="right">
                                              <strong>
                                                {Math.max(
                                                  ...Object.values(
                                                    props.createdBy.winnerTabel,
                                                  ),
                                                  0,
                                                )}
                                              </strong>{" "}
                                              gange
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>

                                    {
                                      /*close*/ false &&
                                        props.warns.map((warn) => (
                                          <div>{warn}</div>
                                        ))
                                    }
                                  </Box>
                                </Paper>
                              }
                            >
                              <HelpIcon
                                style={{ color: "orange" }}
                                className="warn-icon"
                              />
                            </Tooltip>
                          )}
                          {props?.createdBy?.idVerified && (
                            <Tooltip title={"Denne bruger er verificeret"}>
                              <Check className={classes.check} />
                            </Tooltip>
                          )}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="primary">
                          {
                            JOB_POSITIONS.filter(
                              (position) =>
                                parseInt(props.position) === position.id,
                            )[0].name
                          }
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          className={classes.location}
                        >
                          {props.location}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={3} xs={3}>
                    <Avatar
                      alt="logo"
                      className={classes.avatar}
                      src={_.get(
                        props,
                        "createdBy.profile.photo.urlThumbnail",
                        "---",
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.item2}>
                <Typography>
                  {props.description && props.description.length > 48
                    ? props.description.substring(0, 48) + "..."
                    : props.description}
                </Typography>
                {props.prioritiesSelected &&
                  props.prioritiesSelected.map((p) => (
                    <Chip
                      className={classes.priority}
                      variant="outlined"
                      label={TASK_CRITERIES.findNameByValue(p)}
                    />
                  ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              justify="space-between"
              className={classes.bottomDiv}
            >
              <Grid item>
                <Grid container>
                  <Grid item>
                    <WatchLater color="disabled" className={classes.icon} />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {moment(props.dateCreated).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <PanTool color="disabled" className={classes.icon} />
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Typography variant="body2">{props.offers}</Typography>
                      <Typography variant="body2" style={{ marginLeft: 4 }}>
                        bud
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Typography variant="body2" className={classes.money}>
                    {BUDGETS[props.budget].text2}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {enableRate && props.isWaitingForWinner && (
            <Grid item>
              <Grid container>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.buttonRate}
                  onClick={handleChooseWinnerClick}
                >
                  KLIK HER HVIS UDBUDDET ER BLEVET IGANGSAT
                </Button>
              </Grid>
            </Grid>
          )}
          {enableRate && props.winner && props.rate === null && (
            <Grid item>
              <Grid container>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.buttonRate}
                  onClick={handleRateClick}
                >
                  Klik her hvis udbuddet er afsluttet
                </Button>
              </Grid>
            </Grid>
          )}
          {props.rate !== null && (
            <Rating
              name="simple-controlled"
              value={props.rate}
              size="large"
              readOnly={!enableRate}
            />
          )}
        </Grid>
      </Card>
      {openRateDialog && (
        <RateModal
          onCanceled={() => setOpenRateDialog(false)}
          onFinished={handleFinished}
          job={{ ...props }}
        />
      )}
    </>
  );
}

JobCard.propTypes = {
  _id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  budget: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  offers: PropTypes.number.isRequired,
  dateCreated: PropTypes.string.isRequired,
};

export default withStyles(styles)(JobCard);
