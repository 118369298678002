import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { useAuth } from "context/auth";
import log from "loglevel";
import utils from "../utils";

const bucketBaseUrl = "https://corebuis-files.s3.eu-north-1.amazonaws.com/";

const styles = (theme) => ({
  root: {
    minHeight: 800,
  },
});

function JobDetail(props) {
  const { jobId } = useParams();
  const { authToken } = useAuth();
  const [jobDetail, setJobDetail] = useState(null);
  const { classes } = props;

  useEffect(() => {
    const fetchJobDetails = async () => {
      const requestUrl = "/api/jobs/" + jobId;
      Axios.get(requestUrl, {
        headers: {
          Authorization: authToken.token,
        },
      })
        .then((response) => {
          setJobDetail(response.data);
        })
        .catch((e) => {
          log.error("Failed to fetch job details");
          utils.handleAxiosError(e);
        });
    };
    fetchJobDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!jobDetail) {
    return null;
  }
  const url =
    jobDetail && jobDetail.fileName
      ? `${bucketBaseUrl}${jobDetail.fileName}`
      : undefined;

  return (
    <div className={classes.root}>
      {url && (
        <iframe
          title="jobviewer"
          width="100%"
          height="800px"
          src={`https://docs.google.com/gview?url=${url}&embedded=true`}
        ></iframe>
      )}
    </div>
  );
}

export default withStyles(styles)(JobDetail);
