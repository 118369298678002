import React from "react";
import { Box, Grid, Button, Typography, TextField } from "@material-ui/core";
import Axios from "axios";
import { message } from "antd";
import { withStyles } from "@material-ui/core/styles";
import * as utils from "../../utils";
import Joi from "joi";

const styles = (theme) => ({
  input: {
    "& input": {
      padding: "5px",
    },
    width: "100%",
  },
  button: {
    width: "100%",
  },
});

export default withStyles(styles)(function Invitation({
  classes,
  user,
  setIsSignUpOpen,
  authToken,
  skipUserChecking,
  jobId,
}) {
  Joi.assert(jobId, Joi.string().required());
  const [isLoading, setIsLoading] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [job, setJob] = React.useState({});

  function onEmailChange(e) {
    setEmail(e.target.value);
  }

  React.useEffect(() => {
    // load job
    if (jobId) {
      Axios.get(`/api/jobs/${jobId}`, {
        headers: { Authorization: authToken.token },
      })
        .then((res) => {
          setIsLoading(false);
          setJob(res.data);
        })
        .catch((err) => {
          utils.handleAxiosError(err);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleInvite() {
    //check email
    setError("");
    if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
      setError("Indtast venligst en gyldig e-mailadresse");
      return;
    }

    if (!skipUserChecking) {
      if (!user) {
        message.error("ingen brugerdata");
        return;
      }
      if (!user.phone) {
        setIsSignUpOpen(true);
        return;
      }
    }

    // request invite api
    Axios.post(
      "/api/invitations",
      {
        email,
        jobId,
      },
      {
        headers: { Authorization: authToken.token },
      },
    )
      .then((res) => {
        message.success("invitation er sendt");
      })
      .catch((err) => {
        console.error("xxx:", err);
        utils.handleAxiosError(err);
      });
  }

  if (isLoading) {
    return null;
  }

  // job bid started, do not invite
  if (job && job.bidStartedAt) {
    return null;
  }

  return (
    <Box>
      <Typography variant="body1">Inviter nogen du kender til udbud</Typography>
      <Grid container>
        <Grid item sx={12} sm={12}>
          <TextField
            id="email"
            name="email"
            type="text"
            variant="outlined"
            onChange={onEmailChange}
            placeholder="person@firma.dk"
            value={email}
            error={error}
            helperText={error}
            className={classes.input}
          />
        </Grid>
        <Grid item sx={12} sm={12}>
          <Button
            className={classes.button}
            onClick={handleInvite}
            variant="contained"
            color="primary"
          >
            Send invitation
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
});
