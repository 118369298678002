import React from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  root: {
    maxWidth: "80%",
    marginTop: 20,
    position: "absolute",
    margin: 10,
    padding: 19,
    minWidth: 329,
    left: "50%",
    transform: "translate(-50%, 0px)",
  },
});

function CBModal(props) {
  const { classes, children } = props;
  return (
    <Modal open={true} onClose={props.onClose}>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item>{children}</Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
export default withStyles(styles)(CBModal);
