import React, { useState } from "react";
import SignUp from "components/SignUp";
import Login from "components/Login";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import JobFeed from "components/JobFeed";
import CreateTask from "components/CreateTask";
import EditTask from "components/EditTask";
import Profile from "components/ProfileV3";
import JobDetail from "components/JobDetail";
import JobViewer from "components/JobViewer";
import MyJobs from "components/MyJobs";
import MyJobDetail from "components/MyJobDetail";
import MyApplications from "components/MyApplications";
import ApplicationDetail from "components/ApplicationDetail";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthContext } from "./context/auth";
import PrivateRoute from "./PrivateRoute";
import ResetPasswordRequest from "./components/ResetPasswordRequest";
import ResetPassword from "./components/ResetPassword";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Admin from "./components/Admin";
import PrequalificationModal from "./components/PrequalificationModal";
import ChooseWinnerModal from "./components/ChooseWinnerModal";
import ConfirmWinnerModal from "./components/ConfirmWinnerModal";
import AddServicesModal from "./components/AddServicesModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentSandbox from "./components/PaymentSandbox.js";
import PaymentSandboxB from "./components/PaymentSandboxB.js";
import history from "./history";
import log from "loglevel";
import SignUpTwoFactor from "./components/SignUpTwoFactor";
import CooldownModal from "./components/CooldownModal";
import Terms from "./components/Terms";
import About from "./components/About";

log.warn("starts with env:", {
  NODE_ENV: process.env.NODE_ENV,
  ...Object.entries(process.env)
    .filter((k) => k[0].startsWith("REACT_APP"))
    .reduce(
      (a, [key, value]) =>
        Object.assign(a, {
          [key]: /(stripe|key|password)/i.test(key)
            ? value.replaceAll(/\w/g, "*")
            : value,
        }),
      {},
    ),
  // ...Object.entries(process.env).filter((k) => k[0].startsWith("REACT_APP").map((k) => ({k[0]:k[1]})),
});

const stripePK = process.env.REACT_APP_STRIPE_PK;

console.log("strikepk ", stripePK);

const stripePromise = loadStripe(stripePK);

//customize the theme
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3531D8",
    },
    background: {
      default: "#ffffff",
    },
  },
});

function App() {
  const existingToken = JSON.parse(localStorage.getItem("token"));
  const [authToken, setAuthToken] = useState(existingToken);

  const setToken = (data) => {
    localStorage.setItem("token", JSON.stringify(data));
    setAuthToken(data);
  };

  const clearAuthToken = () => {
    localStorage.removeItem("token");
    setAuthToken(null);
  };

  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <AuthContext.Provider
          value={{ authToken, setAuthToken: setToken, clearAuthToken }}
        >
          <Router history={history}>
            <Navbar />

            {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/payment-sandbox" component={PaymentSandbox} />
              <Route path="/payment-sandboxB" component={PaymentSandboxB} />
              <Route path="/test/signup-two-factor">
                <SignUpTwoFactor mode="employee" />
              </Route>
              <Route path="/test/signup-two-factor2">
                <SignUpTwoFactor mode="employer" />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/signup">
                <SignUp />
              </Route>

              {/* The order of the routes is important */}
              <Route
                path="/reset_password/:resetToken"
                component={ResetPassword}
              />
              <Route path="/reset_password/" component={ResetPasswordRequest} />
              <PrivateRoute
                path="/jobs/:jobId/viewer"
                component={JobViewer}
                redirect="/signup"
              />
              <PrivateRoute
                path="/jobs/:jobId"
                component={JobDetail}
                redirect="/signup"
              />
              <PrivateRoute path="/createtask" component={CreateTask} />
              <PrivateRoute path={"/tasks/:taskId"} component={EditTask} />
              <PrivateRoute path={"/profile/:userId"} component={Profile} />
              <PrivateRoute path={"/profile/"} component={Profile} />
              <PrivateRoute path="/myjobs/:jobId" component={MyJobDetail} />
              <PrivateRoute path="/myjobs" component={MyJobs} />
              <PrivateRoute
                path={"/admin/profile/:userId"}
                component={Profile}
              />
              <PrivateRoute path="/admin" component={Admin} />
              <PrivateRoute
                path="/prequalificationModal"
                component={PrequalificationModal}
              />
              <PrivateRoute
                path="/chooseWinnerModal"
                component={ChooseWinnerModal}
              />
              <PrivateRoute
                path="/confirmWinnerModal"
                component={ConfirmWinnerModal}
              />
              <PrivateRoute
                path="/addServicesModal"
                component={AddServicesModal}
              />
              <PrivateRoute path="/cooldownModal" component={CooldownModal} />
              <PrivateRoute
                path="/myapplications/:applicationId"
                component={ApplicationDetail}
              />
              <PrivateRoute path="/myapplications" component={MyApplications} />
              <Route path={"/terms"} component={Terms} />
              <Route path={"/about"} component={About} />
              <PrivateRoute path={["/jobs", "/"]} component={JobFeed} />
            </Switch>
            <Footer />
          </Router>
        </AuthContext.Provider>
      </ThemeProvider>
    </Elements>
  );
}

export default App;
export { theme };
