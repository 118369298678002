import moment from "moment";
import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { useAuth } from "context/auth";
import { PRIORITIES } from "../constants";
import CBSuccess from "./CBSuccess";
import AttachFile from "@material-ui/icons/AttachFile";
import log from "loglevel";
import _ from "lodash";
import { BUDGETS } from "../constants";

const THE_BLUE = "#0889F8";

const styles = (theme) => ({
  root: {},
  paper: {
    margin: theme.spacing(3),
    maxWidth: 800,
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  containerB: {
    marginBottom: theme.spacing(4),
  },
  blue: {
    color: THE_BLUE,
  },
  title: {
    color: THE_BLUE,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  jobType: {
    color: THE_BLUE,
    marginBottom: theme.spacing(3),
  },
  boxItem: {
    margin: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: theme.spacing(1),
  },
  desc: {
    marginBottom: theme.spacing(4),
  },
  label: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
  input: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  submit: {
    width: "100%",
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1.5),
  },
  map: {
    //margin: theme.spacing(-3),
  },
  mapImage: {
    width: "100%",
  },
  box1: {
    marginBottom: theme.spacing(2),
  },
});

function ApplicationDetail(props) {
  const { classes } = props;
  const [application, setApplication] = useState(null);
  const { applicationId } = useParams();
  const { authToken } = useAuth();

  const fetchApplication = async () => {
    const requestUrl = "/api/applications/" + applicationId;
    const response = await Axios.get(requestUrl, {
      headers: {
        Authorization: authToken.token,
      },
    });
    console.log("res:", response.data);
    setApplication(response.data);
    //fetchProfile(response.data.createdBy);
  };

  useEffect(() => {
    fetchApplication();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const bucketBaseUrl = "https://corebuis-files.s3.eu-north-1.amazonaws.com/";

  if (!application || application == null) return null;

  const jobDetail = application.jobId;
  const { createdBy } = jobDetail;

  console.log(application);

  function handleUpload() {
    log.info("handle click file");
    document.querySelector("#file").click();
  }

  function handleFileChange(e) {
    log.info("handle file change:", e);
    var formData = new FormData();
    var file = document.querySelector("#file");
    formData.append("file", file.files[0]);
    Axios.post(`/api/applications/${applicationId}/offer`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: authToken.token,
      },
    }).then((res) => {
      log.info("res of upload:", res);
      //load page
      fetchApplication();
    });
  }

  return (
    <Grid container className={classes.root} justify="center">
      <Paper elevation={0} className={classes.paper}>
        <Grid className={classes.container} container direction="column">
          {application.acceptedForPrequalification &&
            (!application.paid || process.env.REACT_APP_BID_PAYMENT_ENABLED) &&
            !application.offerFileName && (
              <Grid>
                <CBSuccess>
                  Tilykke! Ordregiver har godkendt dig til udvælgelsesfase. Læs
                  den fulde opgavebeskrivelse nøje, og send dit bud til
                  opgavegiver med nedenstående kontaktinformation. Held og lykke
                  med udvælgelsesfasen!
                </CBSuccess>
                <Button
                  onClick={handleUpload}
                  variant="contained"
                  color="primary"
                >
                  UPLOAD TILBUD TIL UDBUD (PDF, DOCX)
                </Button>
                <input
                  id="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  type="file"
                />
              </Grid>
            )}
          {(process.env.REACT_APP_BID_PAYMENT_ENABLED === "false"
            ? false
            : true) &&
            application.acceptedForPrequalification &&
            (application.paid ||
              process.env.REACT_APP_BID_PAYMENT_ENABLED === "false") &&
            !application.offerFileName && (
              <>
                <Grid>
                  <CBSuccess>
                    Tilykke!
                    <br />
                    Ordregiver har godkendt dig til udvælgelsesfase. Læs den
                    fulde opgavebeskrivelse nøje, og send dit bud til
                    opgavegiver med nedenstående kontaktinformation. Held og
                    lykke med udvælgelsesfasen!
                  </CBSuccess>
                </Grid>
              </>
            )}
          {application.acceptedForPrequalification &&
            (application.paid ||
              process.env.REACT_APP_BID_PAYMENT_ENABLED === "false") &&
            application.offerFileName && (
              <Grid>
                <CBSuccess>din tilbudsfil er uploadet.</CBSuccess>
              </Grid>
            )}
          <Grid item>
            <Typography className={classes.title} variant="h5">
              {props.title}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.containerB}
          >
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography className={classes.jobType} variant="h4">
                    {jobDetail.title}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item className={classes.boxItem}>
                    <Typography variant="subtitle2">Fast budget</Typography>
                    <Typography variant="body2" data-testid="budget">
                      {BUDGETS[jobDetail.budget].text}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.boxItem} xs={2}>
                    <Typography variant="subtitle2">Arbejdssted</Typography>
                    <Typography variant="body2">
                      {jobDetail.location}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.boxItem} xs={3}>
                    <Typography variant="subtitle2">
                      Frist for anmodninger
                    </Typography>
                    <Typography variant="body2">
                      {moment(jobDetail.deadline).format("YYYY-MM-DD HH:mm")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    className={clsx(classes.blue)}
                  >
                    Tilbudsgiver
                  </Typography>
                </Grid>
                <Grid container wrap="nowrap">
                  <Grid item>
                    <Avatar
                      className={classes.avatar}
                      alt="logo"
                      src={_.get(
                        createdBy,
                        "profile.photo.urlThumbnail",
                        "http://",
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {_.get(createdBy, "email", "---")}
                    </Typography>
                    <Typography variant="body2">
                      {_.get(createdBy, "firstName", "---")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.desc}>
            <Typography variant="body2">{jobDetail.description}</Typography>
          </Grid>
        </Grid>
        {
          /*application.acceptedForPrequalification && no need to preq for this*/
          jobDetail && jobDetail.fileName && (
            <Grid>
              <Typography variant="h5" className={classes.jobType}>
                Download udbudsmaterialet
              </Typography>
              <Grid container>
                <Grid>
                  <a href={bucketBaseUrl + jobDetail.fileName}>
                    <AttachFile className={classes.attachFile} />
                  </a>
                </Grid>
                <Grid>
                  <a href={bucketBaseUrl + jobDetail.fileName}>
                    <Typography>Udbudsmaterialet</Typography>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          )
        }
        {application.acceptedForPrequalification && (
          <Grid>
            <Typography variant="h5" className={classes.jobType}>
              Kontaktinfo til udvælgelsesfase
            </Typography>
            <Grid item className={classes.box2}>
              <Typography variant="subtitle2">Kontaktperson</Typography>
              <Typography variant="body2">
                {createdBy.firstName} {createdBy.lastName}
              </Typography>
            </Grid>
            <Grid item className={classes.box2}>
              <Typography variant="subtitle2">Adresse</Typography>
              <Typography variant="body2">
                {_.get(createdBy, "profile.streetAddress", "---")}
              </Typography>
              <Typography variant="body2">
                {_.get(createdBy, "profile.postalCode", "---")}{" "}
                {_.get(createdBy, "profile.cityName", "---")}
              </Typography>
            </Grid>
            <Grid item className={classes.box2}>
              <Typography variant="subtitle2">Email</Typography>
              <Typography variant="body2">{createdBy.email}</Typography>
            </Grid>
            <Grid item className={classes.box2}>
              <Typography variant="subtitle2">CVR</Typography>
              <Typography variant="body2">{createdBy.cvr}</Typography>
            </Grid>
          </Grid>
        )}
        <Grid>
          <Typography variant="h5" className={classes.jobType}>
            Din anmodning
          </Typography>
          <Typography variant="subtitle2">
            Kort besked til tilbudsgiver
          </Typography>
          <Typography variant="body2">{application.message}</Typography>
          {application.priorities?.map((priority) => {
            console.log(priority);
            return (
              <>
                <Typography variant="subtitle2">
                  {
                    PRIORITIES.filter(
                      (priorityC) =>
                        priorityC.id === parseInt(priority.priorityId),
                    )[0].name
                  }
                </Typography>
                <Typography variant="body2">{priority.value}</Typography>
              </>
            );
          })}
        </Grid>
      </Paper>
    </Grid>
  );
}

export default withStyles(styles)(ApplicationDetail);
