import React from "react";
import ReactDOM from "react-dom";
import log from "loglevel";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

log.setDefaultLevel(process.env.REACT_APP_LOG_LEVEL || "info");
log.info("Start Bygnetværk client...");
log.debug("Current process.env:", process.env);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
