import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  LinearProgress,
  TextField,
  Typography,
  Link,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { useParams } from "react-router-dom";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Kodeord skal være mindst 8 tegn langt")
    .required("Kodeord mangler"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], "Kodeord er ikke de samme")
    .required("Kodeord bekræftelse mangler"),
});

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loading: {
    marginTop: theme.spacing(6),
  },
  success: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
});

const ResetPassword = ({ classes }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const { resetToken } = useParams();

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setErrorMessage("");
      setSubmitting(true);
      try {
        const result = await axios.post("/auth/reset_password", {
          password: values.password,
          token: resetToken,
        });
        switch (result.status) {
          case 200:
            setIsPasswordReset(true);
            break;
          default:
            setErrorMessage("Der opstod en fejl ved reset.");
        }
      } catch (e) {
        setErrorMessage("Der opstod en fejl ved reset.");
      }
      setSubmitting(false);
    },
  });

  const {
    touched,
    errors,
    isSubmitting,
    handleChange: onChange,
    handleBlur: onBlur,
    handleSubmit,
  } = formik;

  const EmailSentBlock = () => {
    return (
      <p className={classes.success}>
        Kodeord er nu ændret!
        <br />
        Du kan nu <Link href="/login">logge ind på Bygnetværk</Link>.
      </p>
    );
  };

  const SubmittingBlock = () => {
    return (
      <Typography component="h3" variant="h5" className={classes.loading}>
        <LinearProgress />
        Vent venligst...
      </Typography>
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <RotateLeftIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Glemt kodeord
        </Typography>
        {/* eslint-disable-next-line no-mixed-operators */}
        {(isSubmitting && <SubmittingBlock />) ||
          (isPasswordReset && <EmailSentBlock />) || (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Kodeord"
                type="password"
                id="password"
                helperText={touched.password ? errors.password : ""}
                error={touched.password && Boolean(errors.password)}
                onChange={onChange}
                onBlur={onBlur}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="passwordConfirm"
                label="Kodeords bekræftelse"
                type="password"
                id="passwordConfirm"
                helperText={
                  touched.passwordConfirm ? errors.passwordConfirm : ""
                }
                error={
                  touched.passwordConfirm && Boolean(errors.passwordConfirm)
                }
                onChange={onChange}
                onBlur={onBlur}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Skift kodeord
              </Button>
            </form>
          )}
        <Typography variant="subtitle2" color="error">
          {errorMessage}
        </Typography>
      </div>
    </Container>
  );
};

export default withStyles(styles)(ResetPassword);
