import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { useAuth } from "context/auth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Lock from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import Edit from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import log from "loglevel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { Button, Card, Box, CardActions, CardContent } from "@material-ui/core";
import ReviewApplications from "./ReviewApplications";
import AvatarSmall from "./AvatarSmall";
import { JOB_POSITIONS } from "../constants";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1.5),
  },
  email: {
    //maxWidth: 20,
    //wordBreak: "break-all",
  },
  divider: {
    border: "1px solid #e0e0e0",
    borderWidth: 3,
    borderStyle: "dashed",
    marginBottom: 40,
    marginTop: 40,
  },
});

function Admin(props) {
  const [users, setUsers] = React.useState([]);
  const [jobs, setJobs] = React.useState([]);
  const { authToken } = useAuth();
  const { classes } = props;
  const [tabIndex, setTabIndex] = React.useState(0);

  const history = useHistory();

  async function handleDisabled(userId, disabled) {
    await axios
      .request({
        url: `/api/users/${userId}`,
        method: "PATCH",
        data: {
          disabled,
        },
        headers: {
          Authorization: authToken.token,
        },
      })
      .then((res) => {
        log.info("res:", res);
        //refresh
        getData();
      });
  }

  async function getData() {
    try {
      if (tabIndex === 0) {
        const response = await axios.get("/api/users/users", {
          headers: {
            Authorization: authToken.token,
          },
        });
        setUsers(response.data);
      } else if (tabIndex === 1) {
        const response = await axios.get("/api/jobs/admin", {
          headers: {
            Authorization: authToken.token,
          },
        });
        setJobs(response.data);
      } else if (tabIndex === 2) {
        const response = await axios.get("/api/jobs/admin", {
          headers: {
            Authorization: authToken.token,
          },
        });

        //inject disable component
        response.data.forEach((job) => {
          job.applications.forEach((application) => {
            application.disabledComponent = (
              <>
                {application.disabled && (
                  <Tooltip title="Klik for at aktivere kontoen">
                    <IconButton
                      onClick={() =>
                        handleDisabledApplication(
                          application._id,
                          !application.disabled,
                        )
                      }
                    >
                      <Lock />
                    </IconButton>
                  </Tooltip>
                )}
                {!application.disabled && (
                  <Tooltip title="Klik for at inaktivere kontoen">
                    <IconButton
                      onClick={() =>
                        handleDisabledApplication(
                          application._id,
                          !application.disabled,
                        )
                      }
                    >
                      <LockOpen />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            );
          });
        });
        setJobs(response.data);
      } else if (tabIndex === 3) {
        const response = await axios.get(
          "/api/jobs/admin?isMissingInquiries=true&isNotExceededDeadline=true&isActive=true",
          {
            headers: {
              Authorization: authToken.token,
            },
          },
        );
        setJobs(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  React.useEffect(() => {
    getData();
  }, [tabIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleChangeTab(_e, newValue) {
    setTabIndex(newValue);
  }

  async function handleDisabledJob(jobId, disabled) {
    await axios
      .request({
        url: `/api/jobs/${jobId}`,
        method: "PATCH",
        data: {
          disabled,
        },
        headers: {
          Authorization: authToken.token,
        },
      })
      .then((res) => {
        log.info("res:", res);
        //refresh
        getData();
      });
  }

  async function handleDisabledApplication(applicationId, disabled) {
    await axios
      .request({
        url: `/api/applications/${applicationId}`,
        method: "PATCH",
        data: {
          disabled,
        },
        headers: {
          Authorization: authToken.token,
        },
      })
      .then((res) => {
        log.info("res:", res);
        //refresh
        getData();
      });
  }

  return (
    <Grid className={classes.root}>
      <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="list">
        <Tab label="Brugere" />
        <Tab label="udbud" />
        <Tab label="Prækvalifikationer" />
        <Tab label="Mangler henvendelse" />
      </Tabs>
      <TableContainer component={Paper}>
        {tabIndex === 0 && (
          <Table className={classes.table} aria-label="users">
            <TableHead>
              <TableRow>
                <TableCell>Navn</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Faggruppe</TableCell>
                <TableCell>Oprettet opg.</TableCell>
                <TableCell>Anmodninger /PQ vundet</TableCell>
                <TableCell>Deaktiver/Aktiver</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell component="th" scope="row">
                    {user.firstName}
                  </TableCell>
                  <TableCell className={classes.email}>{user.email}</TableCell>
                  <TableCell className={classes.email}>
                    {user.services &&
                      user.services.length > 0 &&
                      user.services[0] !== -1 && (
                        <Box>
                          {user.services
                            .map(
                              (e) =>
                                JOB_POSITIONS.find((ee) => ee.id === e).name,
                            )
                            .join(",")}
                        </Box>
                      )}
                  </TableCell>
                  <TableCell>{user.jobs.length}</TableCell>
                  <TableCell>
                    {user.applications.length}/
                    {user.applications.reduce(
                      (a, c) =>
                        c.acceptedForPrequalification === true ? ++a : a,
                      0,
                    )}
                  </TableCell>
                  <TableCell>
                    {user.disabled && (
                      <Tooltip title="Klik for at aktivere kontoen">
                        <IconButton
                          onClick={() =>
                            handleDisabled(user._id, !user.disabled)
                          }
                        >
                          <Lock />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!user.disabled && (
                      <Tooltip title="Klik for at inaktivere kontoen">
                        <IconButton
                          onClick={() =>
                            handleDisabled(user._id, !user.disabled)
                          }
                        >
                          <LockOpen />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Du redigerer dette som administrator">
                      <IconButton
                        onClick={() =>
                          window.open(`/admin/profile/${user._id}`, "_blank")
                        }
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {tabIndex === 1 && (
          <Table className={classes.table} aria-label="jobs">
            <TableHead>
              <TableRow>
                <TableCell>Udbudnavn</TableCell>
                <TableCell>Anmodninger</TableCell>
                <TableCell>Opretter email</TableCell>
                <TableCell>Deaktiver/Aktiver</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.map((job) => (
                <TableRow key={job._id}>
                  <TableCell component="th" scope="row">
                    {job.title}
                  </TableCell>
                  <TableCell>{job.applications.length}</TableCell>
                  <TableCell>{job.createdBy.email}</TableCell>
                  <TableCell>
                    {job.disabled && (
                      <Tooltip title="Klik for at aktivere kontoen">
                        <IconButton
                          onClick={() =>
                            handleDisabledJob(job._id, !job.disabled)
                          }
                        >
                          <Lock />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!job.disabled && (
                      <Tooltip title="Klik for at inaktivere kontoen">
                        <IconButton
                          onClick={() =>
                            handleDisabledJob(job._id, !job.disabled)
                          }
                        >
                          <LockOpen />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Du redigerer dette som administrator">
                      <IconButton
                        onClick={() =>
                          window.open(`/tasks/${job._id}`, "_blank")
                        }
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {tabIndex === 2 &&
          jobs.map((job) => (
            <div key={job._id}>
              <Typography variant="h5">Udbud: {job.title}</Typography>
              <Typography variant="5">Prækvalifikation:</Typography>
              <ReviewApplications
                handlePreQualification={() => log.warn("fake")}
                handleViewedApplication={() => log.warn("fake")}
                jobApplications={job.applications}
                isAdmin={true}
              />
              <Box className={classes.divider} />
            </div>
          ))}
        {tabIndex === 3 &&
          jobs.map((job) => (
            <Card
              style={{
                margin: "20px 10px",
              }}
              key={job._id}
            >
              <CardContent>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Box
                    style={{
                      margin: 10,
                    }}
                  >
                    <AvatarSmall user={job.createdBy} />
                  </Box>
                  <Box
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography variant="h5">{job.title}</Typography>
                        <Typography variant="subtitle2">
                          {job.createdBy.email}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">
                          CREATED AT:
                          {moment(job.dateCreated).format("yyyy-MM-DD HH:mm")}
                        </Typography>
                        <Typography variant="subtitle2">
                          APPLICANTS:{job.applications.length}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="body">{job.description}</Typography>
                  </Box>
                </Box>
              </CardContent>
              <CardActions
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => history.push(`/jobs/${job._id}`)}
                  size="small"
                  color="primary"
                >
                  Check this job
                </Button>
              </CardActions>
            </Card>
          ))}
      </TableContainer>
    </Grid>
  );
}

export default withStyles(styles)(Admin);
