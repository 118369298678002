/*
 * To display some success information
 */
import React from "react";
import { Box, withStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";

const style = (theme) => ({
  submitted: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1.5, 4),
    backgroundColor: "#04d303",
  },
  box: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1.5, 4),
    backgroundColor: "#04d303",
    color: "#fff",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "border-box",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: 4,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    display: "inline-flex",
    outline: "0",
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
  },
  span: {
    width: "100%",
    display: "inherit",
    alignItems: "inherit",
    justifyContent: "inherit",
  },
  icon: {
    display: "inherit",
    marginLeft: "8px",
    marginRight: "-4px",
  },
});

function CBSuccess(props) {
  const { classes } = props;
  return (
    <Box className={classes.box}>
      <Box component="span" className={classes.span}>
        {props.children}
        <Check className={classes.icon} style={{ fontSize: 35 }} />
      </Box>
    </Box>
  );
}

export default withStyles(style)(CBSuccess);
