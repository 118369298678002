import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Indtast gyldig email").required("Email mangler"),
});

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loading: {
    marginTop: theme.spacing(6),
  },
  success: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
});

const ResetPasswordRequest = ({ classes }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setErrorMessage("");
      setSubmitting(true);
      try {
        const result = await axios.post("/auth/send_reset_email", values);
        switch (result.status) {
          case 200:
            setIsEmailSent(true);
            break;
          default:
            setErrorMessage("Der opstod en fejl ved reset.");
        }
      } catch (e) {
        setErrorMessage("Der opstod en fejl ved reset.");
      }
      setSubmitting(false);
    },
  });

  const {
    touched,
    errors,
    isSubmitting,
    handleChange: onChange,
    handleBlur: onBlur,
    handleSubmit,
  } = formik;

  const EmailSentBlock = () => {
    return (
      <p className={classes.success}>
        En email med instruktioner er blevet sendt til din email, tjek venligst
        også din spammappe.
      </p>
    );
  };

  const SubmittingBlock = () => {
    return (
      <Typography component="h3" variant="h5" className={classes.loading}>
        <LinearProgress />
        Vent venligst...
      </Typography>
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <RotateLeftIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Nulstil kodeord
        </Typography>
        {/* eslint-disable-next-line no-mixed-operators */}
        {(isSubmitting && <SubmittingBlock />) ||
          (isEmailSent && <EmailSentBlock />) || (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                helperText={touched.email ? errors.email : ""}
                error={touched.email && Boolean(errors.email)}
                onChange={onChange}
                onBlur={onBlur}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Send nulstil kodeord email
              </Button>
            </form>
          )}
        <Typography variant="subtitle2" color="error">
          {errorMessage}
        </Typography>
      </div>
    </Container>
  );
};

export default withStyles(styles)(ResetPasswordRequest);
