import Modal from "./common/CBModal2";
import SubscribeDialog from "./SubscribeDialog";
import React, { useState, useRef, useEffect } from "react";
import axios from "../axios";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/auth";
import Header from "@editorjs/header";
import Delimiter from "@editorjs/delimiter";
import ImageTool from "@editorjs/image";
import List from "@editorjs/list";
import Embed from "@editorjs/embed";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import EditorJs from "react-editor-js";
import Undo from "editorjs-undo";
import CompletedJobs from "./CompletedJobs";
import ProfilePicture from "./ProfilePicture";
import { Button, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import jwt from "jwt-decode";
import { message } from "antd";
import CBModal from "./common/CBModal";
import SignUpTwoFactor from "./SignUpTwoFactor";
import log from "loglevel";
import * as utils from "../utils";

export default function Profile() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const { authToken } = useAuth();
  const [profile, setProfile] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoadingAdmin, setIsLoadingAdmin] = useState(true);
  const editorInstance = useRef(null);
  const [subscription, setSubscription] = React.useState(null);
  const [isSubscribing, setIsSubscribing] = React.useState(false);
  const [isCancelSubscribing, setIsCancelSubscribing] = React.useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    gridContainer: {
      alignItems: "start",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    loadProfile(`/api/profiles/${userId}`);
    const jwtUserId = jwt(authToken.token).userId;
    setIsReadOnly(jwtUserId !== userId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function loadProfile(url) {
    const response = await axios.get(url, {
      headers: { Authorization: authToken.token },
    });
    setProfile(response.data);
  }

  const saveProfile = async () => {
    const newData = await editorInstance.current.save();
    axios
      .post("/api/profiles/self", newData, {
        headers: { Authorization: authToken.token },
      })
      .then((resp) => {
        console.log("Saved profile data", resp);
        alert("Dine ændringer er gemt!");
      })
      .catch((err) => {
        console.error(err);
        alert("Der skete en fejl ved gemning af ændringer!");
      });
  };

  const saveProfileAsAdmin = async () => {
    const newData = await editorInstance.current.save();
    axios
      .post(`/api/profiles/${userId}`, newData, {
        headers: { Authorization: authToken.token },
      })
      .then((resp) => {
        console.log("Saved profile data", resp);
        alert("Dine ændringer er gemt!");
      })
      .catch((err) => {
        console.error(err);
        alert("Der skete en fejl ved gemning af ændringer!");
      });
  };

  const handleReady = (editor) => {
    const undo = new Undo({ editor });
    undo.initialize(profile.profileContent);
  };

  function loadSubscription() {
    axios
      .get("/api/users", {
        headers: { Authorization: authToken.token },
      })
      .then((resp) => {
        console.log("Subscription data", resp);
        const user = resp.data;
        setUser(user);
        if (user.isSubscribed) {
          setSubscription(true);
        } else {
          setSubscription(false);
        }
      });
  }

  function handleSubscribe() {
    if (user.isGuest === true) {
      setIsSignUpOpen(true);
    } else if (user.isGuest === false) {
      setIsSubscribing(true);
    } else {
      throw new Error("User isGuest is missing");
    }
  }

  function handleCancelSubscription() {
    setIsCancelSubscribing(true);
  }

  React.useEffect(() => {
    loadSubscription();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSuccess() {
    message.success("Succes med abonnement", 5);
    setIsSubscribing(false);
    setSubscription(true);
  }

  function handleCancelSubscriptionDo() {
    axios
      .delete("/api/users/subscriptions/self", {
        headers: { Authorization: authToken.token },
      })
      .then((resp) => {
        console.log("Cancelled subscription", resp);
        setIsCancelSubscribing(false);
        setSubscription(false);
        message.success("Operationen lykkedes", 5);
      });
  }

  function handleCancelSubscriptionNo() {
    setIsCancelSubscribing(false);
  }

  function handleSignUpSuccess() {
    log.info("Sign up success");
    setIsSignUpOpen(false);
    setIsSubscribing(true);
  }

  //admin check
  useEffect(() => {
    const path = window.location.pathname;
    if (/\/admin\/profile\//.test(path)) {
      axios
        .get("/api/users", {
          headers: { Authorization: authToken.token },
        })
        .then((res) => {
          log.warn("loaded:", res);
          if (res.data.isAdmin) {
            log.warn("set admin mode");
            setIsAdmin(true);
          }
        })
        .catch((err) => {
          utils.handleAxiosError(err);
        })
        .finally(() => {
          setIsLoadingAdmin(false);
        });
    } else {
      setIsLoadingAdmin(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (profile && profile.profileContent && !isLoadingAdmin) {
    console.log("profile", profile);
    return (
      <>
        {!isSubscribing && !isCancelSubscribing && (
          <div className={classes.root}>
            <Grid
              container
              alignItems="center"
              justify="center"
              spacing={3}
              className={classes.gridContainer}
            >
              <Box
                width={1}
                m={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {subscription === true && (
                  <Button
                    onClick={handleCancelSubscription}
                    variant="contained"
                    color="secondary"
                  >
                    Opsig abonnement
                  </Button>
                )}
                {subscription === false && (
                  <Button
                    onClick={handleSubscribe}
                    variant="contained"
                    color="primary"
                  >
                    Offentliggør
                  </Button>
                )}
              </Box>
              <Grid item lg={8} xs={12}>
                {!isReadOnly && (
                  <>
                    <Button
                      onClick={() => saveProfile()}
                      variant="contained"
                      color="primary"
                    >
                      Gem ændringer
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          window.confirm(
                            "Er du sikker på du vil annullere dine ændringer?",
                          )
                        ) {
                          window.location.reload();
                        }
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      Annuller
                    </Button>
                    <ProfilePicture profile={profile} setProfile={setProfile} />
                  </>
                )}
                {isAdmin && (
                  <>
                    <Button
                      onClick={() => saveProfileAsAdmin()}
                      variant="contained"
                      color="primary"
                    >
                      gem ændringer som admin
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          window.confirm(
                            "Er du sikker på du vil annullere dine ændringer?",
                          )
                        ) {
                          window.location.reload();
                        }
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      Annuller som admin
                    </Button>
                    <ProfilePicture profile={profile} setProfile={setProfile} />
                  </>
                )}

                <Paper>
                  <EditorJs
                    readOnly={isReadOnly && !isAdmin}
                    onReady={handleReady}
                    instanceRef={(instance) =>
                      (editorInstance.current = instance)
                    }
                    data={profile.profileContent}
                    tools={{
                      header: Header,
                      list: List,
                      delimiter: Delimiter,
                      image: {
                        class: ImageTool,
                        inlineToolbar: true,
                        config: {
                          additionalRequestHeaders: {
                            Authorization: authToken.token,
                          },
                          endpoints: {
                            byFile: "/api/profiles/uploadphotobyfile",
                          },
                        },
                      },
                      embed: {
                        class: Embed,
                        config: {
                          services: {
                            youtube: true,
                            vimeo: true,
                          },
                        },
                      },
                    }}
                  />
                </Paper>
              </Grid>
              <CompletedJobs userId={userId} authToken={authToken} />
            </Grid>
          </div>
        )}
        {isSubscribing && (
          <Modal>
            <Paper
              style={{
                background: "white",
                maxWidth: "500px",
                overflow: "scroll",
              }}
            >
              <SubscribeDialog onSuccess={handleSuccess} />
            </Paper>
          </Modal>
        )}

        {isCancelSubscribing && (
          <Box>
            <CBModal>
              <Paper
                style={{
                  width: 523,
                  // height: 256,
                  padding: 20,
                }}
              >
                <Typography
                  variant="h4"
                  color="primary"
                  style={{
                    margin: 10,
                  }}
                >
                  Behold Abonnement
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    margin: 10,
                  }}
                >
                  Er du sikker på du vil opsige dit bygnetværk abonnement? Du
                  vil ikke længere kunne henvende dig på ubegrænsede gratis
                  udbud, og din profil vil ikke være offentlig tilgængelig
                  længere.
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    gap: 10,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCancelSubscriptionNo}
                  >
                    Behold abonnement
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCancelSubscriptionDo}
                  >
                    Opsig venligst mit abonnement
                  </Button>
                </Box>
              </Paper>
            </CBModal>
          </Box>
        )}

        {isSignUpOpen && (
          <Box>
            <CBModal>
              <SignUpTwoFactor
                mode="employee"
                onSuccess={handleSignUpSuccess}
              />
            </CBModal>
          </Box>
        )}
      </>
    );
  }

  return <h3>Henter profil...</h3>;
}
