import Axios from "axios";
import history from "./history";

const axios = Axios.create({});

axios.interceptors.response.use(
  (response) => {
    console.log("we are returning response normally");
    console.log(response);
    return response;
  },
  (error) => {
    console.error("There is an error now in axios");
    console.log(error);
    if (error.response && error.response.data && error.response.data.location) {
      console.log("We have a error");
      console.error(error.response);
      console.log(error.response.data);
      history.push(error.response.data.location);
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  },
);

export default axios;
