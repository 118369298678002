import React from "react";
import log from "loglevel";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  infoContainer: {
    marginLeft: theme.spacing(1),
    flexDirection: "column",
  },
  addressItem: {
    marginTop: -5,
  },
});

function AvatarSmallB(props) {
  const { user, classes } = props;
  log.debug("avatar small:", user);
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Avatar
          size="large"
          alt="logo"
          className={classes.avatar}
          src={_.get(user, "profile.photo.url", "http://")}
        />
      </Grid>
      <Grid item>
        <Grid container className={classes.infoContainer}>
          <Grid item>
            <Typography variant="h6">
              {_.get(user, "firstName", "---")}
            </Typography>
          </Grid>
          <Grid item className={classes.addressItem}>
            <Typography variant="body">
              {_.get(user, "profile.streetAddress", "---")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(AvatarSmallB);
