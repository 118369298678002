import React from "react";
import Modal from "@material-ui/core/Modal";
import axios from "../axios";
import { useAuth } from "context/auth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import log from "loglevel";
import { message } from "antd";
import history from "../history";
import JobCard from "./JobCard";
import AvatarSmall from "./AvatarSmall";

const styles = (theme) => ({
  root: {
    maxHeight: "90%",
    overflow: "auto",
    marginTop: 20,
    position: "absolute",
    margin: 10,
    padding: 19,
    maxWidth: 800,
    minWidth: 329,
    left: "50%",
    transform: "translate(-50%, 0px)",
    "& .MuiTableCell-root": {
      padding: 6,
    },
  },
  creditBox: {
    width: "100%",
  },
  payButtonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  payButton: {
    minWidth: 300,
  },
  applicantListBox: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  applicantBox: {
    cursor: "pointer",
  },
});

function ChooseWinnerModal(props) {
  const [open, setOpen] = React.useState(false);
  const { classes } = props;
  const [job, setJob] = React.useState(undefined);
  const [applications, setApplications] = React.useState([]);
  const { authToken } = useAuth();

  async function load() {
    //load jobs waiting for winner
    let res = await axios.request({
      url: "/api/jobs/self/waitingForWinner",
      method: "GET",
      headers: {
        Authorization: authToken.token,
      },
    });
    log.info("res:", res.data);
    //NOTE just deal with the first one
    if (res.data.length === 0) {
      history.push("/myjobs");
      window.location.reload();
    }
    const job = res.data[0];
    setJob(job);

    //load all pre-qualified applicant
    res = await axios.request({
      url: `/api/jobs/${job._id}/applications?acceptedForPrequalification=true`,
      method: "GET",
      headers: {
        Authorization: authToken.token,
      },
    });
    log.info("res:", res.data);
    setApplications(res.data);

    setOpen(true);
  }

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
  };

  async function handleClick(application) {
    await axios.request({
      url: `/api/jobs/${job._id}/chooseWinner`,
      method: "PUT",
      headers: {
        Authorization: authToken.token,
      },
      data: {
        winner: application._id,
      },
    });

    message.success("Tak for din konfirmation!", 5);
    setTimeout(() => {
      history.push("/myjobs");
      window.location.reload();
    }, 2000);
  }

  return (
    <>
      <Modal open={open}>
        <form onSubmit={handleSubmit}>
          <Paper className={classes.root}>
            <Grid container>
              <Grid item>
                <Typography variant="h5" color="primary">
                  Hvem vandt udbuddet?
                </Typography>
                {job && <JobCard {...job} enableRate={false} disableClick />}
                <Typography variant="body">
                  Klik på den kandidat som endte med at vinde udbud.
                </Typography>
                <Grid container className={classes.applicantListBox}>
                  {applications.map((application) => (
                    <Grid
                      item
                      onClick={() => handleClick(application)}
                      className={classes.applicantBox}
                    >
                      <AvatarSmall user={application.applicantId} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Modal>
    </>
  );
}

export default withStyles(styles)(ChooseWinnerModal);
