import React from "react";
import { BUDGETS } from "../constants";
// import Modal from "@material-ui/core/Modal";
import axios from "axios";
import { useAuth } from "context/auth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Card, Box } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Warning } from "@material-ui/icons";
import log from "loglevel";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { message } from "antd";
import history from "../history";
import Payment from "./Payment";

const styles = (_theme) => ({
  root: {
    maxHeight: "80vh",
    overflow: "auto",
    // marginTop: 20,
    // position: "absolute",
    // margin: 10,
    padding: 19,
    maxWidth: 800,
    minWidth: 329,
    margin: 20,
    left: "50%",
    // transform: "translate(-50%, 0px)",
    "& .MuiTableCell-root": {
      padding: 6,
    },
  },
  creditBox: {
    width: "100%",
  },
  payButtonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  payButton: {
    minWidth: 300,
  },
  payment: {
    marginTop: 10,
  },
});

function PrequalificationModal(props) {
  const [open, setOpen] = React.useState(false);
  const { classes } = props;
  const [price, setPrice] = React.useState(0.0);
  const [job, setJob] = React.useState(undefined);
  const [billsAhead, setBillsAhead] = React.useState([]);
  const [competitors, setCompetitors] = React.useState([]);
  const [secret, setSecret] = React.useState(undefined);
  const { authToken } = useAuth();

  async function load() {
    //get all applications for current user
    const res = await axios.request({
      url: "/api/applications/prequalification-modal",
      method: "GET",
      headers: {
        Authorization: authToken.token,
      },
    });
    log.info("res:", res.data);
    const price = res.data.paymentAmount;
    const { job, billsAhead } = res.data;
    log.info("Get prequalified applications: price:%d", price);
    setPrice(price);
    setJob(job);
    setBillsAhead(billsAhead);
    setSecret(res.data.paymentSecret);
    setOpen(true);

    // load competitors count
    const res2 = await axios.request({
      url: `/api/jobs/${job._id}/competitors`,
      method: "GET",
      headers: {
        Authorization: authToken.token,
      },
    });
    log.info("get competitors:", res2);
    const { list } = res2.data;
    setCompetitors(list);
  }

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // load user data

    const res = await axios.request({
      url: "/api/users",
      method: "get",
      headers: {
        Authorization: authToken.token,
      },
    });
    const user = res.data;
    if (!user) {
      throw new Error("can not load user data");
    }

    const result = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        },
      },
      setup_future_usage: "off_session",
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        log.info("payment success!");
        axios
          .request({
            url: "/api/applications/prequalification-modal",
            method: "post",
            data: {
              secret,
            },
            headers: {
              Authorization: authToken.token,
            },
          })
          .then((res) => {
            log.info("res:", res);
            if (res.status === 200) {
              message.success("Betaling lykkedes!", 5);
              setTimeout(() => {
                console.log("The history:");
                console.log(history);
                // if (history.length > 1) {
                //   history.goBack();
                // } else {
                history.push("/jobs");
                window.location.reload();
                // }
              }, 2000);
            }
          })
          .catch((err) => {
            message.error("Noget gik galt", 5);
          });
      } else {
        log.warn("payment result:", result.paymentIntent);
      }
    }
  };

  return (
    <>
      {job && (
        <Paper elevation={11} className={classes.root}>
          <Grid container>
            <Grid item>
              <Typography variant="h5" color="primary">
                Tillykke! Du er kommet videre til udvælgelsesfase
              </Typography>
              <Typography variant="subtitle1">
                <strong>Udbud:</strong> {job.title}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Priser</strong>
              </Typography>
              <Typography variant="body1">
                Prisen for at blive accepteret til budrunde afhænger af udbuddets
                budget og hvor mange virksomheder du skal konkurrere med til
                udvælgelsesfase. Desto færre virksomheder, jo større chance har
                du for at vinde udbuddet!
              </Typography>
              <TableContainer component={"div"}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Antal virksomheder til udvælgelsesfase
                      </TableCell>
                      <TableCell>
                        Pris for at komme til udvælgelsesfase
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {BUDGETS[job.budget].prices.map((e, i) => (
                      <TableRow>
                        <TableCell align="center" component="th" scope="row">
                          {i === 3 ? ">=" + (i + 1) : i + 1}
                        </TableCell>
                        <TableCell align="center">{e}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="body1">
                Denne udbud har et budget på {job && BUDGETS[job.budget].text},
                denne udbud har{" "}
                <strong>{competitors?.length} virksomheder</strong> som er
                kommet videre til udvælgelsesfase, inkl. din virksomhed, prisen
                for at komme til udvælgelsesfase er derfor{" "}
                <strong>{price} DKK</strong>.
              </Typography>
            </Grid>
            <Grid item className={`${classes.creditBox} ${classes.payment}`}>
              {open && <Payment onPay={handleSubmit} />}
            </Grid>
            {billsAhead.length > 0 && (
              <Card
                variant="outlined"
                style={{
                  padding: 10,
                }}
              >
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{
                      gap: 10,
                    }}
                  >
                    <Warning color="secondary" />
                    <Typography variant="h5">Bemærk Venligst</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      Du betaler for din første ansøgning, der er stadig
                      {billsAhead.length}
                      ansøgning(er), der skal betales forud, de er:
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <TableContainer component={"div"}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Udbud Titel</TableCell>
                          <TableCell>Pris for Denne Udbud</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {billsAhead.map((e, i) => (
                          <TableRow>
                            <TableCell align="center">{e.job.title}</TableCell>
                            <TableCell align="center">{e.price}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Card>
            )}
          </Grid>
        </Paper>
      )}
      {!job && <div>loading...</div>}
    </>
  );
}

export default withStyles(styles)(PrequalificationModal);
