import React from "react";
import axios from "../axios";
import { useAuth } from "context/auth";
import log from "loglevel";
import { Box, Typography } from "@material-ui/core";
import Payment from "./Payment";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import utils from "../utils";

export default function SubscribeDialog(props) {
  const { authToken } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const [secret, setSecret] = React.useState(undefined);
  const [subscriptionId, setSubscriptionId] = React.useState(undefined);

  async function load() {
    const res = await axios.request({
      url: "/api/users/subscriptions/prepare",
      method: "GET",
      headers: {
        Authorization: authToken.token,
      },
    });

    // check 200 response
    if (res.status !== 200) {
      utils.handleAxiosError(res);
    } else {
      setSecret(res.data.paymentSecret);
      setSubscriptionId(res.data.subscriptionId);
    }
  }

  const handleSubmit = async (event) => {
    log.warn("handleSubmit");
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      log.error("stripe or elements is undefined");
      return;
    }

    // load user data
    const res = await axios.request({
      url: "/api/users",
      method: "get",
      headers: {
        Authorization: authToken.token,
      },
    });
    const user = res.data;
    if (!user) {
      throw new Error("can not load user data");
    }

    const result = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        },
      },
      // setup_future_usage: "off_session",
    });

    log.warn("result of stripe.confirmCardPayment:", result);

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      log.error("Get error when stripe paying...");
      console.error(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        log.info("payment success!");
        const res = await axios.request({
          url: "/api/users/subscriptions/pay",
          method: "post",
          headers: {
            Authorization: authToken.token,
          },
          data: {
            secret,
            subscriptionId,
          },
        });
        log.info("res:", res);
        if (res.status === 200) {
          props.onSuccess();
        } else {
          utils.handleAxiosError(res);
        }
      } else {
        log.warn("payment result:", result.paymentIntent);
      }
    }
  };

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box p={2}>
      <Typography variant="h4" color="primary">
        Bygnetværk Abonnement
      </Typography>
      <Typography variant="body1">
        Din gratis periode er ovre. Tegn abonnement og få vist din profil når du
        henvender dig på udbud. Abonnement: 250 kr. om måneden inkl. moms.
      </Typography>
      <Box height={10} />
      <Payment onPay={handleSubmit} />
    </Box>
  );
}
