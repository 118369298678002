import React, { useState } from "react";
import {
  LinearProgress,
  Checkbox,
  Grid,
  Box,
  TextField,
  FormControlLabel,
  CssBaseline,
  Button,
  Avatar,
  Typography,
  Container,
  Link,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { withStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useAuth } from "../context/auth";
import Copyright from "components/Copyright";
import { useHistory } from "react-router-dom";
import log from "loglevel";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgetPassword: {
    margin: theme.spacing(2, 0),
  },
});

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Indtast gyldig email").required("Email mangler"),
  password: Yup.string().required("Kodeord mangler"),
});

const Login = (props) => {
  const history = useHistory();
  const { setAuthToken } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setErrorMessage("");
      setSubmitting(true);
      try {
        const result = await axios.post("/auth/login", values);
        setAuthToken(result.data);
        history.push("/jobs");
      } catch (e) {
        log.warn("error when login", e.response);
        switch (e.response.status) {
          case 409:
            setErrorMessage("Email er allerede oprettet");
            break;
          case 401:
            if (e.response.data.message) {
              setErrorMessage(e.response.data.message);
            } else {
              setErrorMessage("Forkert email eller password");
            }
            break;
          default:
            setErrorMessage("Der opstod en fejl ved login.");
        }
      }
      setSubmitting(false);
    },
  });
  const {
    touched,
    errors,
    isSubmitting,
    handleChange: onChange,
    handleBlur: onBlur,
    handleSubmit,
  } = formik;
  const { classes } = props;

  if (isSubmitting) {
    return (
      <Typography component="h3" variant="h5">
        <LinearProgress />
        Vent venligst...
      </Typography>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log ind
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            helperText={touched.email ? errors.email : ""}
            error={touched.email && Boolean(errors.email)}
            onChange={onChange}
            onBlur={onBlur}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Kodeord"
            type="password"
            id="password"
            autoComplete="current-password"
            helperText={touched.password ? errors.password : ""}
            error={touched.password && Boolean(errors.password)}
            onChange={onChange}
            onBlur={onBlur}
          />
          <Grid container justify="space-between">
            <Grid item>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Husk mig"
              />
            </Grid>
            <Grid item>
              <Box className={classes.forgetPassword}>
                <Link href="/reset_password" variant="body2">
                  Glemt kodeord?
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Typography variant="subtitle2" color="error">
            {errorMessage}
          </Typography>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Log ind på Bygnetværk
          </Button>
          <Grid container justify="center">
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Har du ikke en konto? Opret bruger"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default withStyles(styles)(Login);
