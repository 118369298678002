import {
  Box,
  Typography,
  MenuItem,
  Chip,
  FormGroup,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { JOB_POSITIONS } from "../../constants";

const styles = (theme) => ({
  positionSelect: {
    "& .MuiSelect-select": {
      padding: 14,
    },
  },
  isFree: {
    marginTop: 10,
    minWidth: 250,
    "& .MuiFormLabel-root": {
      marginTop: "-12px",
    },
  },
  menuItem: {
    "&.Mui-selected": {
      backgroundColor: "#3531d8",
      color: "white",
    },
  },
});

export default withStyles(styles)(function ServicesSelector({
  classes,
  handlePositionChange,
  position,
}) {
  return (
    <FormGroup
      row
      style={{
        marginTop: "10px",
        maxWidth: "100%",
      }}
    >
      <FormControl variant="outlined" className={classes.isFree}>
        <InputLabel htmlFor="age-native-simple">Faggruppe</InputLabel>
        <Select
          fullWidth
          defaultValue={position}
          id="position-selector"
          variant="outlined"
          onChange={handlePositionChange}
          className={classes.positionSelect}
          value={position}
          multiple
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <>
                  <Chip
                    color="primary"
                    className={`${classes.priority} ${classes.chosen}`}
                    key={value}
                    label={JOB_POSITIONS.reduce(
                      (a, c) => (value === c.id ? c.name : a),
                      "",
                    )}
                  />
                </>
              ))}
            </div>
          )}
        >
          <Box p={1}>
            <Typography variant="caption">
              klik et vilkårligt sted uden for listen for at afvise
            </Typography>
          </Box>
          <MenuItem value={-1}>Alle faggrupper</MenuItem>
          {/* sort by name in alphabetical order */}
          {JOB_POSITIONS.sort((a, b) => a.name.localeCompare(b.name)).map(
            (item) => {
              return (
                <MenuItem value={item.id} className={classes.menuItem}>
                  {item.name}
                </MenuItem>
              );
            },
          )}
        </Select>
      </FormControl>
    </FormGroup>
  );
});
