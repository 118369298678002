import { Box } from "@material-ui/core";

export default function CBModal2(props) {
  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: 20,
        paddingBottom: 100,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {props.children}
    </Box>
  );
}
