import React from "react";
import { CircularProgress, Box, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import log from "loglevel";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import logo from "../images/stripe_logo.png";
import logoVisa from "../images/VISA_card.svg";
import logoMaster from "../images/MASTERCARD_card.svg";
import logoMaestro from "../images/MAESTRO_card.svg";
import logElectron from "../images/ELECTRON_card.svg";
import cvcIcon from "../images/cvv-icon.png";
import redErrorIcon from "../images/red-error-icon.svg";
import smallCheckmark from "../images/small-checkmark.svg";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "inherit",
      fontSmoothing: "antialiased",
      fontSize: "21px",
      lineHeight: "33px",
      "::placeholder": {
        color: "#aab7c4",
      },
      ":focus": {
        borderColor: "rgb(140, 191, 229)",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
      border: "1px solid #fa755a",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  paymentContainer: {
    boxSizing: "border-box",
    color: "#212529",
    fontSize: "1rem",
    fontWeight: "400",
    fontFamily: "rawline,Arial,Sans-serif",
    lineHeight: "1.5",
    textAlign: "left",
    "& input, .StripeElement": {
      // boxShadow: "none",
    },
    "& .main": {
      backgroundColor: "#efefef",
      color: "#212529",
      display: "flex",
      flexDirection: "column",
    },
    "& .brand": {
      height: "25px",
      margin: "10px 4% 2px 0px",
    },
    "& .gray": {
      WebkitFilter: "grayscale(1)",
    },
    "& .box1": {
      backgroundColor: "white",
      padding: "0 20px",
      [theme.breakpoints.down("xs")]: {
        padding: "0 5px",
      },
    },
    "& .box2": {
      backgroundColor: "white",
      padding: theme.spacing(3, 2),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2, 1),
      },
    },
    "& .text1": {
      fontSize: 20,
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
      },
    },
    "& .box3": {
      // height: "55px",
      // borderBottomColor: "rgb(235, 241, 246)",
      // borderBottomStyle: "solid",
      // borderBottomWidth: "1px",
      // borderRadius: 5,
    },
    "& .message": {
      marginTop: -16,
      fontSize: "14px",
      color: "#dd636b",
      minHeight: "21px",
    },
    "& .box5": {
      display: "flex",
      gap: "16px",
      flexWrap: "nowrap",
      // breakpoint xs css
      [theme.breakpoints.down("xs")]: {
        flexWrap: "nowrap",
        gap: 4,
      },
    },
    "& .box6": {
      // width: "100%",
      // flexGrow: 1,
    },
  },
  stripeInput: {
    "& .StripeElement": {
      height: 55,
      color: "#495057",
      borderColor: "#ebf1f6",
      borderWidth: "1px",
      borderRadius: 5,
      borderStyle: "solid",
      transitionDuration: "0s",
      boxShadow: "none",
    },
    "& .StripeElement--focus": {
      borderColor: "#8cbfe5",
      boxShadow: "0 0 0 0.1rem rgb(45 132 196 / 25%)",
    },
    "& .StripeElement--invalid": {
      borderColor: "#dd636b",
      boxShadow: "none",
      borderWidth: "2px",
      backgroundImage: `url(${redErrorIcon})`,
      backgroundSize: "25px",
      backgroundPosition: "98% 13px",
      backgroundRepeat: "no-repeat",
    },
    "& .StripeElement--complete": {
      borderColor: "#14a16f",
      boxShadow: "none",
      borderWidth: "2px",
      backgroundImage: `url(${smallCheckmark})`,
      backgroundSize: "25px",
      backgroundPosition: "98% 13px",
      backgroundRepeat: "no-repeat",
    },
  },
  stripeInputCvc: {
    "& .StripeElement": {
      height: 55,
      color: "#495057",
      borderColor: "#ebf1f6",
      borderWidth: "1px",
      borderRadius: 5,
      borderStyle: "solid",
      transitionDuration: "0s",
      background: `url(${cvcIcon}) no-repeat scroll right`,
      backgroundSize: "50px",
      backgroundOrigin: "content-box",
      backgroundColor: "#fff",
      boxShadow: "none",
    },
    "& .StripeElement--focus": {
      borderColor: "#8cbfe5",
      boxShadow: "0 0 0 0.1rem rgb(45 132 196 / 25%)",
    },
    "& .StripeElement--invalid": {
      borderColor: "#dd636b",
      boxShadow: "none",
      borderWidth: "2px",
    },
    "& .StripeElement--complete": {
      borderColor: "#14a16f",
      boxShadow: "none",
      borderWidth: "2px",
    },
  },
  button: {
    fontSize: "20px",
    fontWeight: 600,
    textTransform: "none",
    backgroundColor: "#14a16f",
    boxShadow: "none",
    color: "white",
    width: "100%",
    padding: "20px",
    border: "none",
    letterSpacing: "1.5px",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "4px",
    backgroundClip: "padding-box",
    minHeight: "64px",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  buttonDisabled: {
    cursor: "not-allowed",
    position: "relative",
    minHeight: "24px",
    backgroundColor: "#8a8a8a",
  },
  box8: {
    padding: "20px 8%",
    borderTop: "1px solid #e9ecef",
    letterSpacing: "1px",
    backgroundColor: "#f8f9fa",
    [theme.breakpoints.down("xs")]: {
      padding: "5px 15px",
    },
  },
  box9: {
    alignItems: "center!important",
    display: "flex",
    flexWrap: "wrap",
    marginRight: "-15px",
    marginLeft: "-15px",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));

export default function Payment(props) {
  const [message, setMessage] = React.useState("");
  const [expireMessage, setExpireMessage] = React.useState("");
  const [cvcMessage, setCvcMessage] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [isNumberValid, setIsNumberValid] = React.useState(false);
  const [isExpireValid, setIsExpireValid] = React.useState(false);
  const [isCvcValid, setIsCvcValid] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const classes = useStyles();

  function handleChange(e) {
    log.warn("e:", e);
    if (e.error) {
      setMessage(e.error.message);
    } else {
      setMessage("");
    }
    if (e.brand) {
      setBrand(e.brand);
    } else {
      setBrand("");
    }
    if (e.complete) {
      setIsNumberValid(true);
    } else {
      setIsNumberValid(false);
    }
  }

  function handleChangeExpire(e) {
    log.warn("e:", e);
    if (e.error) {
      setExpireMessage(e.error.message);
    } else {
      setExpireMessage("");
    }
    if (e.complete) {
      setIsExpireValid(true);
    } else {
      setIsExpireValid(false);
    }
  }

  function handleChangeCvc(e) {
    log.warn("e:", e);
    if (e.error) {
      setCvcMessage(e.error.message);
    } else {
      setCvcMessage("");
    }
    if (e.complete) {
      setIsCvcValid(true);
    } else {
      setIsCvcValid(false);
    }
  }

  function handleSubmit(event) {
    setIsSubmitting(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    log.warn("handleSubmit");
    props.onPay();
    // setTimeout(() => {
    //   setIsSubmitting(false);
    // }, 2000);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.paymentContainer}>
        <div className="main">
          <div className={classes.box8}>
            <div className={classes.box9}>
              <div
                style={{
                  flex: "0 0 41.6666666667%",
                  maxWidth: "41.6666666667%",
                  fontSize: 18,
                  fontWeight: 500,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div>Betalingskort</div>
              </div>
              <div
                style={{
                  display: "flex",
                  verticalAlign: "middle",
                  textAlign: "left",
                  padding: "0",
                }}
              >
                <img
                  alt="visa"
                  className={`brand ${brand && brand !== "visa" ? "gray" : ""}`}
                  src={logoVisa}
                />
                <img
                  alt="visa"
                  className={`brand ${
                    brand && brand !== "master" ? "gray" : ""
                  }`}
                  src={logoMaster}
                />
                <img
                  alt="visa"
                  className={`brand ${
                    brand && brand !== "maestro" ? "gray" : ""
                  }`}
                  src={logoMaestro}
                />
                <img
                  alt="visa"
                  className={`brand ${
                    brand && brand !== "electron" ? "gray" : ""
                  }`}
                  src={logElectron}
                />
              </div>
            </div>
          </div>
          <Divider
            style={{
              backgroundColor: "#e8eaee",
            }}
          />
          <Box className="box1">
            <Box className="box2">
              <Box className="text1">Kortnummer</Box>
              <Box className="box3">
                <Box className={classes.stripeInput}>
                  <CardNumberElement
                    options={{ ...CARD_ELEMENT_OPTIONS, placeholder: "" }}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box className="message">{message}</Box>
              <Grid container className="box5">
                <Grid item xs={6} sm={6} className="box6">
                  <Box className="text1">Udløbsdato</Box>
                  <Box className={classes.stripeInput}>
                    <CardExpiryElement
                      options={CARD_ELEMENT_OPTIONS}
                      onChange={handleChangeExpire}
                    />
                  </Box>
                  <Box className="message">{expireMessage}</Box>
                </Grid>
                <Grid item xs={6} sm={6} className="box6">
                  <Box className="text1">Kontrolcifre</Box>
                  <Box>
                    <Box className={classes.stripeInputCvc}>
                      <CardCvcElement
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={handleChangeCvc}
                      />
                    </Box>
                  </Box>
                  <Box className="message">{cvcMessage}</Box>
                </Grid>
              </Grid>
              {isSubmitting && (
                <button
                  className={classes.button}
                  onClick={() => {
                    log.warn("is submitting...");
                  }}
                  variant="contained"
                >
                  <CircularProgress
                    size={25}
                    style={{ width: 25, height: 25, color: "white" }}
                  />
                </button>
              )}
              {!isSubmitting && (
                <button
                  type="submit"
                  className={`${classes.button} ${
                    !isNumberValid || !isExpireValid || !isCvcValid
                      ? classes.buttonDisabled
                      : ""
                  }`}
                  disabled={!isNumberValid || !isExpireValid || !isCvcValid}
                >
                  Betal
                </button>
              )}
            </Box>
          </Box>
        </div>
        <Box>
          <div
            style={{
              justifyContent: "flex-end",
              borderTop: "1px solid #e9ecef",
              backgroundColor: "#fff",
              padding: "14px 0",
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              WebkitBoxAlign: "start",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                WebkitBoxAlign: "center",
                alignItems: "center",
                WebkitBoxPack: "end",
                justifyContent: "flex-end",
                color: "#868e96",
                padding: "0 1em",
                fontFamily: "rawline,Arial,Sans-serif",
                fontSize: "18px",
                textAlign: "left",
                fontWeight: "400",
                lineHeight: "1.5",
              }}
            >
              Sikker betaling med
              <div class="reepay">
                <img
                  style={{
                    width: 60,
                    filter: "grayscale(1)",
                  }}
                  src={logo}
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </form>
  );
}
