import { Grid, Hidden, Box, Typography } from "@material-ui/core";
import about from "../images/about.png";
import video from "../images/about.mp4";
import cover from "../images/cover.png";

export default function Terms() {
  return (
    <Box p={4}>
      <Typography variant="h6" align="center" style={{ marginTop: 20 }}>
        Udbuds- og tilbudsportal for professionelle - <br />
        og deres kunder
      </Typography>
      {/* a video file */}
      <Grid container direction="row" justify="center">
        <Grid item xs={12} sm={6}>
          <video
            style={{ width: "100%" }}
            controls
            src={video}
            //cover
            poster={cover}
          />
        </Grid>
      </Grid>
      <Typography variant="body1" align="center" style={{ marginTop: 32 }}>
        Bygnetvaerk.dk følger intentionerne i<br />
        Tilbudslov (og udbudslov)
        <br />
        Begrænset licitation
        <br />
        Sådan virker det med{" "}
        <a href="http://www.bygnetvaerk.dk">www.bygnetvaerk.dk</a>
        <br />
      </Typography>
      <Box mt={8}>
        <Hidden smDown>
          <img alt="workflow timeline" style={{ width: "100%" }} src={about} />
        </Hidden>
      </Box>
    </Box>
  );
}
