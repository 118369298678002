import { Box, Typography } from "@material-ui/core";

export default function Terms() {
  return (
    <Box p={2}>
      <Typography variant="h5">1. Virksomhedsoplysninger</Typography>
      <Typography variant="body1">Bygnetværk ApS</Typography>
      <Typography variant="body1">43007742</Typography>
      <Typography variant="body1">Skovbrynet 2D, 3.18</Typography>
      <Typography variant="body1">Kongens Lyngby</Typography>
      <Box height={10} />
      <Typography variant="h5">2. Priser og betaling</Typography>
      <Typography variant="body1">
        Det er gratis at være på Bygnetvaerk.dk, men anvender du funktionerne
        mere end en gang kræver det et abonnement som betales på siden med 250
        kr. inkl. moms om måneden – beløbet trækkes automatisk efter første gang
        indtil abonnement opsiges.Der er ingen binding ud over den betalte
        periode. Du kan altid rekvirere kvitteringer for dine betalinger til
        regnskabet m.v.
      </Typography>
      <Box height={10} />
      <Typography variant="h5">3. Levering</Typography>
      <Typography variant="body1">
        Levering af ydelsen fra Bygnetvaerk.dk består i forskellige funktioner
        på Bygnetvaerk.dk. der bliver åbnet for på brugerenprofil efter kunden.
      </Typography>
      <Box height={10} />
      <Typography variant="h5">4. Ansvar</Typography>
      <Typography variant="body1">
        Bygnetværks brugere har ansvar for at læse op på
        forretningsbetingelserne og acceptere, at mail, adresse, telefonnummer
        og navn bliver synlig for andre brugere.
      </Typography>
      <Typography variant="body1">
        Bygnetvaerk.dk har ikke ansvar at funktionerne kan være fejlbehæftet,
        men vil til enhver tid får det forsøgt rettet, hvis der konstateres
        fejl. Tilbud, aftaler og anden forhold mellem mere end en bruger er
        Bygnetvaerk.dk uvedkommen.
      </Typography>
      <Typography variant="body1">
        Virksomheden tager ansvar for at behandle feedback, kritik og klager med
        seriøsitet og reagere hurtigst muligt på at forbedre brugeroplevelse og
        tryghed på siden. Sidens indhold bliver modereret i bruger indhold ved
        at fjerne opslag der ikke er relevante for siden samt pause og slette
        kontoer der efter ledelsens vurdering ikke er velkommen på siden.
      </Typography>
      <Box height={10} />
      <Typography variant="h5">5. Klager</Typography>
      <Typography variant="body1">
        Transaktioner på hjemmesiden Bygnetvaerk.dk er under brugers eget
        ansvar. opstår i form af betalingsvægge der åbner op for konkrete
        handlinger eller informationer på siden.  Ydelsen er i den forbindelse
        eksekveret direkte efter betaling og returret finder derfor ikke sted.
        Kontakt offentlige myndigheder og eller egen bank med anmeldelse, hvis
        du mistænker identitetstyveri og/eller, at dit betalingskort er blevet
        misbrugt.
      </Typography>
      <Box height={10} />
      <Typography variant="h5">6. Databehandling</Typography>
      <Typography variant="body1">
        Din data er gemt i eksterne servere der løbende kan skiftes, efter
        IT-sikkerheds-anbefalinger, hvis det er nødvendigt for sikkerheden på
        hjemmesiden. Ingen brugeroplysninger sælges sendes eller overføres til
        andre med undtagelse for nøglepersoner som investorer og programmør der
        på virksomhedsledelsens tilladelse får lov til at gennemgå vores
        database enten med supervision eller på egen hånd i forbindelse med
        bland andet opdatering af vores tekniske processer og markedsføring af
        Bygnetvaerk.dk.
      </Typography>
      <Box height={10} />
      <Typography variant="h5">7. Klagemuligheder</Typography>
      <Typography variant="body1">
        Det er muligt at klage på <strong>mail@bygnetvaerk.dk</strong>. En
        klage behandles typisk indenfor 7 dage.
      </Typography>
      <Box height={10} />
      <Typography variant="h5">8. Kontakt</Typography>
      <Typography variant="body1">
        <strong>mail@bygnetvaerk.dk</strong>
      </Typography>
      <Box height={10} />
      <Typography variant="h5">9. Ændringer</Typography>
      <Typography variant="body1">
        Virksomheden kan løbende ændre handelsbetingelser. Reviderede versioner
        opbevares i 2 uger for at bidrage med at kunne styrke sikkerhed i
        klagesager. Desuden forbeholder Bygnetvaerk.dk sig retten til løbende at
        kunne udvikle (ændre, fjerne, tilføje) funktioner og løsninger, hvilket
        skal kunne tolereres.
      </Typography>
      <Box height={10} />
      <Typography variant="h5">10. Lov og værneting</Typography>
      <Typography variant="body1">
        Det er Danmarks love der gælder for handler på bygnetvaerk.dk.
      </Typography>
      <Typography variant="body1">
        I en eventuel sag vedrørende uoverensstemmelser (sagsanlæg) vil en sådan
        sag skulle indbringes i byretten i Lyngby.
      </Typography>
    </Box>
  );
}
